@import "global.scss";

.Character {
  position: relative;
  overflow: visible;

  &.adult {
    width: 20.5vh;
    height: 65vh;
    position: relative;
    overflow: visible;
  }

  &.child {
    width: 18.5vh;
    height: 55vh;
    position: relative;
    overflow: visible;
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 0);
    overflow: hidden;
    user-select: none;
    background-size: contain;
    background-repeat: no-repeat;

    &.outfit {
      height: 90%;
    }

    &.hairStyle {
      z-index: +4; // Sometimes hair style should render above accessories (such as glasses or scarf).
      height: 20%; // Prevents the hair div to overlap with buttons
    }

    &.accessory {
      height: 15%;
    }
  }

  /* adult svgs */
  span.skinTone.adult-skinTone-1 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("adult-skinTone-1.svg");
  }

  span.skinTone.adult-skinTone-2 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("adult-skinTone-2.svg");
  }

  span.skinTone.adult-skinTone-3 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("adult-skinTone-3.svg");
  }

  span.skinTone.adult-skinTone-4 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("adult-skinTone-4.svg");
  }

  span.skinTone.adult-skinTone-5 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("adult-skinTone-5.svg");
  }

  span.outfit.adult-outfit-1 {
    @include align-absolutePosition(11%, auto, auto, -1%);
    background-image: url("adult-outfit-1.svg");
    width: 107%;
  }

  span.outfit.adult-outfit-2 {
    @include align-absolutePosition(11%, auto, auto, -2%);
    background-image: url("adult-outfit-2.svg");
    width: 109%;
  }

  span.outfit.adult-outfit-3 {
    @include align-absolutePosition(11.5%, auto, auto, 0);
    background-image: url("adult-outfit-3.svg");
    width: 104%;
  }

  span.outfit.adult-outfit-4 {
    @include align-absolutePosition(11.5%, auto, auto, -2.5%);
    background-image: url("adult-outfit-4.svg");
    width: 144%;
    z-index: +1; // Should render on top of watch.
  }

  span.outfit.adult-outfit-5 {
    @include align-absolutePosition(11.5%, auto, auto, -3%);
    background-image: url("adult-outfit-5.svg");
    width: 106%;
  }

  span.hairStyle.adult-hairStyle-black {
    @include align-absolutePosition(-3%, auto, auto, 34%);
    background-image: url("adult-hairStyle-black.svg");
    width: 33%;
  }

  span.hairStyle.adult-hairStyle-black-curly {
    @include align-absolutePosition(-4%, auto, auto, 30%);
    background-image: url("adult-hairStyle-black-curly.svg");
    width: 41%;
  }

  span.hairStyle.adult-hairStyle-blonde {
    @include align-absolutePosition(-4%, auto, auto, 32%);
    background-image: url("adult-hairStyle-blonde.svg");
    width: 39%;
    height: 22%; // Long hair, svg needs more height
  }

  span.hairStyle.adult-hairStyle-brown {
    @include align-absolutePosition(-3%, auto, auto, 25%);
    background-image: url("adult-hairStyle-brown.svg");
    width: 53%;
  }

  span.hairStyle.adult-hairStyle-grey {
    @include align-absolutePosition(-3%, auto, auto, 22%);
    background-image: url("adult-hairStyle-grey.svg");
    width: 57%;
  }

  span.accessory.adult-accessory-glasses {
    @include align-absolutePosition(2.5%, auto, auto, 38%);
    background-image: url("adult-accessory-glasses.svg");
    width: 25%;
  }

  span.accessory.adult-accessory-scarf {
    @include align-absolutePosition(9%, auto, auto, 33%);
    background-image: url("adult-accessory-scarf.svg");
    width: 37%;
    z-index: +3;
  }

  span.accessory.adult-accessory-yellow-hat {
    @include align-absolutePosition(-7.5%, auto, auto, 7%);
    background-image: url("adult-accessory-yellow-hat.svg");
    width: 88%;
    z-index: +5; // Should render on top of hair style.
  }

  span.accessory.adult-accessory-white-hat {
    @include align-absolutePosition(-5%, auto, auto, 21%);
    background-image: url("adult-accessory-white-hat.svg");
    width: 59%;
    z-index: +5; // Should render on top of hair style.
  }

  span.accessory.adult-accessory-watch {
    @include align-absolutePosition(46%, auto, auto, 85%);
    background-image: url("adult-accessory-watch.svg");
    width: 11%;
  }

  // Adult Trimmed SVGS
  span.accessory.adult-accessory-yellow-hat + span.hairStyle.adult-hairStyle-black-curly,
  span.accessory.adult-accessory-white-hat + span.hairStyle.adult-hairStyle-black-curly {
    background-image: url("adult-hairStyle-black-curly-trimmed.svg");
  }

  span.accessory.adult-accessory-yellow-hat + span.hairStyle.adult-hairStyle-blonde,
  span.accessory.adult-accessory-white-hat + span.hairStyle.adult-hairStyle-blonde {
    background-image: url("adult-hairStyle-blonde-trimmed.svg");
  }

  span.adult-accessory-yellow-hat + span.hairStyle.adult-hairStyle-brown,
  span.accessory.adult-accessory-white-hat + span.hairStyle.adult-hairStyle-brown {
    background-image: url("adult-hairStyle-brown-trimmed.svg");
  }

  /* child svgs */
  span.skinTone.child-skinTone-1 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("child-skinTone-1.svg");
  }

  span.skinTone.child-skinTone-2 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("child-skinTone-2.svg");
  }

  span.skinTone.child-skinTone-3 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("child-skinTone-3.svg");
  }

  span.skinTone.child-skinTone-4 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("child-skinTone-4.svg");
  }

  span.skinTone.child-skinTone-5 {
    @include align-absolutePosition(0, auto, auto, 0);
    background-image: url("child-skinTone-5.svg");
  }

  span.outfit.child-outfit-1 {
    @include align-absolutePosition(15%, auto, auto, -3.5%);
    background-image: url("child-outfit-1.svg");
    width: 106%;
  }

  span.outfit.child-outfit-2 {
    @include align-absolutePosition(15%, auto, auto, -3%);
    background-image: url("child-outfit-2.svg");
    width: 105%;
  }

  span.outfit.child-outfit-3 {
    @include align-absolutePosition(15.5%, auto, auto, -3.5%);
    background-image: url("child-outfit-3.svg");
    width: 108%;
  }

  span.outfit.child-outfit-4 {
    @include align-absolutePosition(15%, auto, auto, -3.5%);
    background-image: url("child-outfit-4.svg");
    width: 107%;
  }

  span.outfit.child-outfit-5 {
    @include align-absolutePosition(15%, auto, auto, -1.5%);
    background-image: url("child-outfit-5.svg");
    width: 104%;
  }

  span.hairStyle.child-hairStyle-black {
    @include align-absolutePosition(-1.5%, auto, auto, 25.5%);
    background-image: url("child-hairStyle-black.svg");
    width: 44.5%;
  }

  span.hairStyle.child-hairStyle-black-curly {
    @include align-absolutePosition(-1.5%, auto, auto, 28.5%);
    background-image: url("child-hairStyle-black-curly.svg");
    width: 43%;
  }

  span.hairStyle.child-hairStyle-blonde {
    @include align-absolutePosition(-1.5%, auto, auto, 29%);
    background-image: url("child-hairStyle-blonde.svg");
    width: 41%;
  }

  span.hairStyle.child-hairStyle-brown {
    @include align-absolutePosition(-5%, auto, auto, 20%);
    background-image: url("child-hairStyle-brown.svg");
    width: 61%;
    height: 22%; // Long Hair needs more height
  }

  span.hairStyle.child-hairStyle-ginger {
    @include align-absolutePosition(-1%, auto, auto, 30%);
    background-image: url("child-hairStyle-ginger.svg");
    width: 37%;
  }

  span.accessory.child-accessory-lei {
    @include align-absolutePosition(14%, auto, auto, 31.5%);
    background-image: url("child-accessory-lei.svg");
    width: 36%;
  }

  span.accessory.child-accessory-cap {
    @include align-absolutePosition(-4%, auto, auto, 24%);
    background-image: url("child-accessory-cap.svg");
    width: 50%;
    z-index: +5; // Should render on top of hair style.
  }

  span.accessory.child-accessory-choker {
    @include align-absolutePosition(50%, auto, auto, 2.5%);
    background-image: url("child-accessory-choker.svg");
    width: 13%;
  }
  span.accessory.child-accessory-hat {
    @include align-absolutePosition(-2%, auto, auto, 29.5%);
    background-image: url("child-accessory-hat.svg");
    width: 38%;
    z-index: +5; // Should render on top of hair style.
  }

  span.accessory.child-accessory-wristbands {
    @include align-absolutePosition(49%, auto, auto, 4%);
    background-image: url("child-accessory-wristbands.svg");
    width: 93%;
  }

  // Child Trimmed SVGS

  span.accessory.child-accessory-cap + span.hairStyle.child-hairStyle-black,
  span.accessory.child-accessory-hat + span.hairStyle.child-hairStyle-black {
    background-image: url("child-hairStyle-black-trimmed.svg");
  }

  span.accessory.child-accessory-cap + span.hairStyle.child-hairStyle-black-curly,
  span.accessory.child-accessory-hat + span.hairStyle.child-hairStyle-black-curly {
    background-image: url("child-hairStyle-black-curly-trimmed.svg");
  }

  span.accessory.child-accessory-cap + span.hairStyle.child-hairStyle-blonde,
  span.accessory.child-accessory-hat + span.hairStyle.child-hairStyle-blonde {
    background-image: url("child-hairStyle-blonde-trimmed.svg");
  }

  span.accessory.child-accessory-cap + span.hairStyle.child-hairStyle-brown,
  span.accessory.child-accessory-hat + span.hairStyle.child-hairStyle-brown {
    background-image: url("child-hairStyle-brown-trimmed.svg");
  }
}
