@import "global.scss";
@import "common/items.scss";

.DinnerCooking {
  background: $green-0;

  @media only screen and (max-width: $media-md) {
    height: rem-calc(780);
  }

  button.item:not(.recycle-bin) {
    @media only screen and (max-width: $media-md) {
      min-width: rem-calc(140);
      max-width: rem-calc(210);
      margin-left: rem-calc(4);
      margin-right: rem-calc(4);

      &:first-child {
        margin-left: rem-calc(32);
      }

      &:last-child {
        margin-right: rem-calc(32);
      }
    }

    @media only screen and (max-width: $media-sm) {
      min-width: rem-calc(120);
    }
  }

  button.item label {
    @media only screen and (max-width: $media-md) {
      border-radius: rem-calc(6) !important;
      top: rem-calc(64) !important;
    }
  }

  &:before {
    @include background-image("../elements/blobs", "blob-dinner-cooking.svg", no-repeat, center center, 80%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      background-size: rem-calc(531) rem-calc(311);
      top: rem-calc(-510);
    }
  }

  .background-elements {
    @include align-absolutePosition(0, auto, 0, 0);
    width: 60vw;
    height: 80vh;

    @media only screen and (min-width: $media-sm) and (max-width: $media-md) {
      margin-left: rem-calc(-90);
    }

    @media only screen and (max-width: $media-md) {
      width: 50%;
      bottom: auto;
      top: rem-calc(318);
      height: rem-calc(520);
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(258);
    }

    .item.utensils {
      @include background-image(
        "../elements/items",
        "utensils.svg",
        no-repeat,
        center center,
        100%,
        100%,
        36.5vh,
        14.5vh
      );
      @include align-absolutePosition(10%, 46%, auto, auto);
      margin: 0;

      @media only screen and (max-width: $media-md) {
        width: rem-calc(218);
        height: rem-calc(86);
        @include align-absolutePosition(0, rem-calc(-35), auto, auto);
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(154);
        height: rem-calc(61);
        @include align-absolutePosition(0, rem-calc(25), auto, auto);
      }
    }
    .item.stove {
      @include background-image("../elements/items", "stove.svg", no-repeat, center center, 100%, 100%, 28.5vh, 44.5vh);
      @include align-absolutePosition(35%, 55%, auto, auto);
      margin: 0;

      @media only screen and (max-width: $media-md) {
        width: rem-calc(167);
        height: rem-calc(263);
        @include align-absolutePosition(rem-calc(106), 0, auto, auto);
        margin-right: rem-calc(26);
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(118);
        height: rem-calc(185);
        @include align-absolutePosition(rem-calc(66), 0, auto, auto);
        margin-right: rem-calc(56);
      }
    }
    .item.table {
      @media only screen and (min-width: $media-md) {
        @include background-image(
          "../elements/items",
          "dinner-table.svg",
          no-repeat,
          center center,
          100%,
          100%,
          38vh,
          45vh
        );
        @include align-absolutePosition(auto, 10%, 0, auto);
        margin: 0;
        z-index: +1;
      }
    }
  }

  .primary-elements {
    @include align-absolutePosition(0, 0, 0, auto);
    width: rem-calc(282);
    height: rem-calc(208);

    @media only screen and (max-width: $media-md) {
      left: 0;
      width: 100%;
      bottom: auto;
      top: 0;
    }

    @media only screen and (min-width: $media-md) {
      width: 40vw;
      height: 80vh;
    }

    // fridge --- only for mobile
    @media only screen and (max-width: $media-md) {
      &:before {
        @include background-image(
          "../elements/items",
          "dinner-fridge-with-items.svg",
          no-repeat,
          center center,
          100%,
          100%,
          rem-calc(231),
          rem-calc(378)
        );

        @include align-absolutePosition(rem-calc(290), auto, auto, 50%);
        margin-left: rem-calc(80);
      }
    }

    &:before {
      @media only screen and (max-width: $media-sm) {
        margin-left: rem-calc(24) !important;
        width: rem-calc(163);
        height: rem-calc(267);
        @include align-absolutePosition(rem-calc(235), auto, auto, 50%);
      }
    }

    .item.fridge {
      @media only screen and (max-width: $media-md) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      @media only screen and (max-width: $media-sm) {
        overflow-x: scroll;
        justify-content: center;
        .has-more-before,
        .has-more-after {
          justify-content: flex-start;
        }
      }

      @media only screen and (min-width: $media-md) {
        @include background-image(
          "../elements/items",
          "dinner-fridge.svg",
          no-repeat,
          center center,
          100%,
          100%,
          38vh,
          62vh
        );

        @include align-absolutePosition(10%, 45%, auto, auto);
        margin: 0;
      }

      @media only screen and (max-width: $media-md) {
        width: 100%;
        height: rem-calc(148);
        top: rem-calc(88);
        right: auto;
        margin-left: 0;
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(76);
      }
    }

    .item {
      &.precooked-meal {
        position: relative;

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(6.5vh, auto, auto, 3vh);
          label {
            top: -4vh;
            right: 8vh;
            left: auto;
            width: min-content;
          }
        }
      }
      &.frozen-pizza {
        position: relative;

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(38.3vh, auto, auto, 5vh);
          label {
            top: 7.5vh;
          }
          &:before {
            background-repeat: space;
          }
        }
      }
      &.rice-and-salad {
        position: relative;

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(27.5vh, auto, auto, 7vh);
          label {
            top: 0vh;
            right: 15vh;
            left: -14vh;
          }
        }
      }
      &.ham-sandwich-and-soft-drink {
        position: relative;

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(7vh, auto, auto, 12vh);
          label {
            top: -4.5vh;
            right: -13vh;
            left: auto;
            width: fit-content;
          }
        }
      }
      &.wholegrain-pasta-with-sauce {
        position: relative;

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(17.5vh, auto, auto, 3vh);
          label {
            top: -1vh;
            right: -22vh;
            left: 15vh;
          }
        }
      }
      &:not(.selected):disabled {
        filter: grayscale(100%) contrast(50%) brightness(140%);
      }
    }
  }

  .Character {
    @include align-absolutePosition(17%, 19%, auto, auto);

    &:before {
      @include background-image(
        "../elements/blobs",
        "character-breakfast-dinner-cooking-shadow.svg",
        no-repeat,
        center center,
        100%,
        100%,
        28vh,
        10vh
      );
      @include align-absolutePosition(auto, 10%, -5%, -17%);
    }

    &.child {
      &:before {
        left: -25%;
      }
    }
  }

  #open-character-modal:target .Character {
    @media only screen and (max-width: $media-md) {
      position: fixed;
    }
  }

  button.recycle-bin {
    @include align-absolutePosition(auto, 38vh, -2vh, auto);
    width: 16.5vh;
    height: 29.5vh;

    @media only screen and (max-width: $media-md) {
      width: rem-calc(99);
      height: rem-calc(167);
      @include align-absolutePosition(rem-calc(532), auto, auto, 50%);
      margin-left: rem-calc(28);
    }

    @media only screen and (max-width: $media-sm) {
      width: rem-calc(70);
      height: rem-calc(118);
      top: rem-calc(402);
      margin-left: rem-calc(-12);
    }

    z-index: +1;
    svg {
      width: 100%;
      height: 100%;
      #recyclable-items {
        opacity: 0;
      }
      #sign {
        animation: scale-up-center 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite both;
        transform-origin: 50% 50% 0;
      }
    }
    &:disabled {
      svg #recyclable-items {
        opacity: 1;
      }
      #sign {
        animation: none;
      }
    }
  }

  // has-more after & before
  .has-more-before:before,
  .has-more-after:after {
    background-color: $green-2;
  }
}

html:lang(lt) .DinnerCooking .primary-elements .item.fridge,
html:lang(pl) .DinnerCooking .primary-elements .item.fridge {
  @media only screen and (max-width: $media-sm) {
    top: rem-calc(57);
    height: rem-calc(170);
  }
}
