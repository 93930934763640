@import "global.scss";
@import "common/items.scss";
@import "common/Animations.scss";

.DinnerDelivery {
  background: $restaurant-1;

  button.item label {
    @media only screen and (max-width: $media-md) {
      border-radius: rem-calc(6) !important;
    }
  }

  @media only screen and (max-width: $media-md) {
    height: rem-calc(440);
  }

  &:before {
    @include background-image("../elements/blobs", "blob-dinner-delivery.svg", no-repeat, center center, 80%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      background-size: rem-calc(442) rem-calc(302);
      top: rem-calc(-630);
    }
  }

  .background-elements {
    @include align-absolutePosition(0, auto, 0, 0);

    @media only screen and (max-width: $media-md) {
      width: 100%;
      height: rem-calc(500);
      top: rem-calc(230);
      bottom: auto;
      left: rem-calc(-50);
      right: auto;
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(180);
      transform: scale(0.8);
    }

    // Table - only for mobile
    @media only screen and (max-width: $media-md) {
      &:before {
        @include background-image(
          "../elements/items",
          "delivery-stand.svg",
          no-repeat,
          center center,
          100%,
          100%,
          rem-calc(343),
          rem-calc(184)
        );

        @include align-absolutePosition(rem-calc(-20), auto, auto, 50%);
        margin-left: rem-calc(120);
      }
    }

    @media only screen and (min-width: $media-md) {
      width: 70vw;
      height: 70vh;
    }

    .item.door {
      @include background-image(
        "../elements/items",
        "delivery-door.svg",
        no-repeat,
        center center,
        100%,
        100%,
        21vh,
        51vh
      );
      @include align-absolutePosition(5%, 48%, auto, auto);

      @media only screen and (max-width: $media-md) {
        width: rem-calc(91);
        height: rem-calc(220);
        @include align-absolutePosition(rem-calc(40), 50%, auto, auto);
      }
    }

    .item.scooter {
      @include background-image(
        "../elements/items",
        "delivery-scooter.svg",
        no-repeat,
        center center,
        100%,
        100%,
        58vh,
        60.5vh
      );

      @include align-absolutePosition(auto, 33%, -10%, auto);
      z-index: +1;
      animation: slide-in 0.55s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      --translateX: -125%;

      @media only screen and (max-width: $media-md) {
        width: rem-calc(245);
        height: rem-calc(256);
        top: rem-calc(104);
        right: auto;
        bottom: auto;
        left: 50%;
        margin-left: -150px;
      }
    }

    @media only screen and (min-width: $media-md) {
      .item.stool {
        @include background-image(
          "../elements/items",
          "delivery-stool.svg",
          no-repeat,
          center center,
          100%,
          100%,
          17.5vh,
          34vh
        );

        @include align-absolutePosition(auto, 22%, -5%, auto);
      }
    }

    .item.plant {
      @include background-image(
        "../elements/items",
        "delivery-plant.svg",
        no-repeat,
        center center,
        100%,
        100%,
        17vh,
        27vh
      );

      @include align-absolutePosition(auto, 5%, -5%, auto);

      @media only screen and (max-width: $media-md) {
        width: rem-calc(87);
        height: rem-calc(141);
        top: rem-calc(190);
        right: auto;
        bottom: auto;
        left: 50%;
        margin-left: 140px;
      }
    }
  }

  .primary-elements {
    @include align-absolutePosition(0, 0, 0, auto);
    width: 100%;
    height: 100%;

    @media only screen and (min-width: $media-md) {
      width: 70vw;
      height: 70vh;
    }

    .item.window {
      @include background-image(
        "../elements/items",
        "delivery-stand.svg",
        no-repeat,
        center center,
        100%,
        100%,
        67vh,
        36vh
      );

      @media only screen and (max-width: $media-md) {
        @include align-absolutePosition(rem-calc(70), auto, auto, auto);
        margin-left: rem-calc(-170);
        width: 100%;
        margin-left: 0;
        height: rem-calc(138);
        background-image: none;
        display: flex;
        align-items: stretch;
        justify-content: center;
      }

      @media only screen and (max-width: $media-sm) {
        justify-content: center;
        overflow-x: scroll;
        &.has-more-before,
        &.has-more-after {
          justify-content: start;
        }
      }

      @media only screen and (min-width: $media-md) {
        @include align-absolutePosition(0, auto, auto, 18%);
      }

      .item {
        // cheeseburger
        &.cheeseburger {
          @include align-absolutePosition(rem-calc(120), auto, auto, 50%);
          margin-left: rem-calc(-135);
          label {
            top: rem-calc(80);
          }

          @media only screen and (min-width: $media-md) {
            @include align-absolutePosition(auto, auto, 3.5vh, 35vh);
            label {
              top: 11.5vh;
            }
          }
        }

        // mushroom-risoto
        &.mushroom-risoto {
          @include align-absolutePosition(rem-calc(114), auto, auto, 50%);
          margin-left: rem-calc(45);
          label {
            top: rem-calc(70);
          }

          @media only screen and (min-width: $media-md) {
            @include align-absolutePosition(auto, auto, 22.5vh, 37vh);
            label {
              top: 8.5vh;
            }
          }
        }

        // noodles-with-vegetables
        &.noodles-with-vegetables {
          @include align-absolutePosition(rem-calc(275), auto, auto, 50%);
          margin-left: rem-calc(-135);
          label {
            top: rem-calc(100);
            width: fit-content;
          }

          @media only screen and (min-width: $media-md) {
            @include align-absolutePosition(auto, auto, 3.5vh, 19vh);
            label {
              top: 11.5vh;
            }
          }
        }

        // pizza-and-soft-drink
        &.pizza-and-soft-drink {
          @include align-absolutePosition(rem-calc(310), auto, auto, 50%);
          margin-left: rem-calc(35);
          label {
            top: rem-calc(80);
          }

          @media only screen and (min-width: $media-md) {
            @include align-absolutePosition(auto, auto, 22.5vh, 18vh);
            label {
              top: 10.5vh;
            }
          }
        }
        &:not(.selected):disabled {
          filter: grayscale(100%) contrast(50%) brightness(140%);
        }

        // general rules for all items and all itme's labels
        @media only screen and (max-width: $media-md) {
          position: static !important;
          margin: rem-calc(8) !important;

          &:first-child {
            margin-left: rem-calc(16) !important;
          }

          &:last-child {
            margin-right: rem-calc(16) !important;
          }

          label {
            position: static !important;
            font-size: rem-calc(12);
            display: block;
            min-width: rem-calc(92);
          }
        }

        &:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(72);
            height: rem-calc(72);
          }

          @media only screen and (max-width: $media-sm) {
            width: rem-calc(48);
            height: rem-calc(48);
          }
        }
      }
    }

    .Character {
      @include align-absolutePosition(0, auto, -15%, 60%);
      z-index: +1;

      &:before {
        @include background-image(
          "../elements/blobs",
          "character-breakfast-dinner-delivery-shadow.svg",
          no-repeat,
          center center,
          100%,
          100%,
          28vh,
          10vh
        );
        @include align-absolutePosition(auto, 10%, -5%, -17%);
      }

      &.child {
        &:before {
          left: -25%;
        }
      }
    }
  }

  // has-more after & before
  .has-more-before:before,
  .has-more-after:after {
    background-color: $restaurant--1;
  }
}

// Rules only for -- bg

html:lang(bg) .DinnerDelivery .primary-elements .item.window {
  top: rem-calc(65);
}

// Rules only for -- nl
html:lang(nl) .DinnerDelivery .primary-elements .item.window .item.mushroom-risoto label {
  @media only screen and (min-width: $media-md) {
    left: -1.2vw;
    right: -1.2vw;
  }
}

//et - lang
// ---------------------------------------------------------------------------------
html:lang(fi) {
  .DinnerDelivery .primary-elements .item.window .item.cheeseburger label {
    @media only screen and (min-width: $media-md) {
      left: -10%;
      width: 110%;
    }
  }
}
