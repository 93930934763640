@import "global.scss";

body:not(.-scene-completed) .Timeline {
  @media only screen and (max-width: $media-md) {
    width: 100%;
  }
}

.Timeline {
  width: 100vw;

  .-scene-completed & {
    @media only screen and (max-width: $media-md) {
      left: 0;
      right: auto;
      width: 86%;
    }

    @media only screen and (max-width: $media-md) {
      width: 84%;
    }

    @media only screen and (max-width: $media-xs) {
      width: 76%;
    }
  }

  h2 {
    background: gray;
    color: $white;
    border-radius: 2rem;
    padding: rem-calc(12);
    font-size: rem-calc(12);

    @media only screen and (max-width: $media-sm) {
      max-width: rem-calc(140);
      text-align: center !important;
      padding: rem-calc(6) rem-calc(16);
      line-height: rem-calc(18);
    }

    @media only screen and (max-width: $media-xs) {
      max-width: rem-calc(110);
      padding: rem-calc(3) rem-calc(16);
    }

    @media only screen and (min-width: $media-sm) {
      font-size: rem-calc(14);
      padding: rem-calc(14);
    }

    @media only screen and (min-width: $media-md) {
      padding: 1rem;
      font-size: 2.8vh;
    }
  }

  ol {
    list-style-type: none;
    @extend %inline-list;
    justify-content: space-between;

    @media only screen and (max-width: $media-md) {
      height: rem-calc(56);
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      background-color: $grey;
      opacity: 0.3;
      width: 99%;

      @media only screen and (max-width: $media-md) {
        width: 104%;
        left: -2%;
      }

      @media only screen and (min-width: $media-sm) {
        width: 80vw;
      }

      height: rem-calc(2);
    }

    li {
      position: relative;

      &.dot {
        @extend %no-text;
        width: 0;
        height: 0;
        padding: rem-calc(6);

        @media only screen and (min-width: $media-md) {
          padding: 0.5%;
        }

        border-radius: 100%;
        user-select: none;
        background: gray;

        &.past {
          background: $green-2;
        }
      }
    }
  }

  // character-set-up
  &.character-set-up {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          display: block;
        }
      }
    }
  }
  // end of --- character-set-up

  // breakfast
  &.breakfast {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          display: block;
        }
      }
    }
  }
  // end of --- breakfast

  // transportation
  &.transportation {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          display: block;
        }
      }
    }
  }
  // end of --- transportation

  // lunch
  &.lunch {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          display: block;
        }
      }
    }
  }
  // end of --- lunch

  // breaktime
  &.breaktime {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: block;
        }
      }
    }
  }
  // end of --- breaktime

  // snacking
  &.snacking {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: block;
        }
      }
    }
  }
  // end of --- snacking

  // supermarket
  &.supermarket {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: block;
        }
      }
    }
  }
  // end of --- supermarket

  // dinner-selection
  &.dinner-selection {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: block;
        }
        &:nth-child(9) {
          display: none !important;
        }
      }
    }
  }
  // end of --- dinner-selection

  // dinner-delivery
  &.dinner-delivery {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(9) {
          display: block;
        }
      }
    }
  }
  // end of --- dinner-delivery, dinner-cooking

  // dinner-cooking
  &.dinner-cooking {
    ol li {
      @media only screen and (max-width: $media-md) {
        display: none;

        &:nth-child(9) {
          display: block;
        }
      }
    }
  }
  // end of --- dinner-delivery, dinner-cooking
}

@each $name, $code in $scene-colours {
  .Timeline.#{$name} ol li.dot {
    background: $code !important;
  }
}

@each $name, $code in $timeline-colours {
  .Timeline.#{$name} ol li h2 {
    background: $code !important;
  }
  .Timeline.#{$name} ol li.dot {
    border: 3px solid $code !important;
  }
  .Timeline.#{$name} ol li.dot.past {
    background: $code !important;
  }
}

@each $name, $code in $timeline-dot-colours {
  .Timeline.#{$name} ol li.dot {
    background: $code !important;
  }
}
