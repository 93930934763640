@import "global.scss";

// Note that common modal CSS is implemented in App.scss.
.AvailableItemsAndSelections {
  .items {
    display: flex;
    align-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    overflow: auto;
    height: 80vh;

    .item {
      flex-basis: 100%;

      @media only screen and (min-width: $media-sm) {
        flex-basis: 40%;
      }

      &.no-drink .item-text h3 {
        @media only screen and (max-width: $media-xs) {
          margin-bottom: 0;
        }
      }

      background-color: $white;
      box-shadow: 2px 2px 0 $grey-1;
      border-radius: 2%;
      margin: 2%;
      padding: 3%;

      .item-text {
        @media only screen and (min-width: $media-xs) {
          display: flex;
          justify-content: space-between;
        }

        h3 {
          text-align: center;

          @media only screen and (max-width: $media-md) {
            width: rem-calc(130);
            margin-right: rem-calc(16);
            font-size: rem-calc(16);
          }

          @media only screen and (max-width: $media-xs) {
            width: auto;
            text-align: left;
            display: flex;
            align-items: center;
            margin-bottom: rem-calc(16);
            margin-right: 0;
          }

          @media only screen and (min-width: $media-md) {
            flex-basis: 30%;
          }

          &:before {
            margin: 0 auto;
            margin-bottom: 15%;
            display: block;
            content: " ";
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 10vh;
            height: 10vh;
            @media only screen and (max-width: $media-xs) {
              width: rem-calc(50);
              min-width: rem-calc(50);
              max-width: rem-calc(50);
              height: rem-calc(50);
              margin-left: 0;
              margin-right: rem-calc(9);
              margin-bottom: 0;
            }
          }
        }

        p {
          @extend %no-space;

          @media only screen and (max-width: $media-md) {
            width: calc(100% - rem-calc(146));
          }

          @media only screen and (max-width: $media-xs) {
            width: 100%;
          }

          @media only screen and (min-width: $media-md) {
            flex-basis: 70%;
          }
        }
      }
    }
  }
}
