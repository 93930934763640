@import "global.scss";
@import "common/items.scss";
@import "common/Animations.scss";

@keyframes leaveShelves {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 10em;
  }
}
@keyframes enterInventory {
  0% {
    opacity: 0;
    margin-top: -10em;
  }
  100% {
    opacity: 1;
    margin-top: 0;
    transform: translate(0, 2vh);
  }
}

.Timeline.supermarket {
  height: rem-calc(70);
}

.Supermarket {
  background: $yellow-0;

  @media only screen and (max-width: $media-md) {
    height: rem-calc(1135);
  }

  @media only screen and (max-width: $media-sm) {
    height: rem-calc(935);
  }

  @media only screen and (min-width: $media-md) {
    overflow: hidden;
  }

  &:before {
    @include background-image("../elements/blobs", "blob-supermarket.svg", no-repeat, center center, 80%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      background-size: rem-calc(780) rem-calc(379);
      top: rem-calc(-835);
    }

    @media only screen and (max-width: $media-sm) {
      background-size: rem-calc(480) rem-calc(279);
    }
  }

  .primary-items {
    @include align-absolutePosition(0, 0, 0, 0);

    &:before {
      @media only screen and (max-width: $media-md) {
        @include background-image(
          "../elements/items",
          "super-market-background-mobile.svg",
          no-repeat,
          center center,
          rem-calc(736),
          rem-calc(480)
        );

        @include align-absolutePosition(rem-calc(310), 0, auto, 50%);
        margin-left: rem-calc(-418);
        width: rem-calc(736);
        height: rem-calc(480);
      }

      @media only screen and (max-width: $media-sm) {
        @include align-absolutePosition(rem-calc(280), 0, auto, 50%);
        margin-left: rem-calc(-428);
      }

      @media only screen and (max-width: $media-xs) {
        width: rem-calc(509);
        height: rem-calc(332);
        margin-left: rem-calc(-309);
        background-size: rem-calc(509) rem-calc(332);
        top: rem-calc(245);
      }
    }

    @media only screen and (max-width: $media-md) {
      bottom: auto;
    }

    width: 100%;
    height: 100%;
    @media only screen and (min-width: $media-md) {
      width: 85vw;
      height: 100vh;
    }

    .shelves {
      @media only screen and (max-width: $media-md) {
        display: flex;
        position: absolute;
        width: calc(100% - rem-calc(32));
        height: rem-calc(58);
        padding-bottom: rem-calc(120);
        @include align-absolutePosition(0, auto, auto, auto);
        margin-top: rem-calc(90);
        overflow: hidden;
        overflow-x: scroll;
        padding-right: rem-calc(32);
      }

      @media only screen and (max-width: $media-xs) {
        margin-top: rem-calc(60);
        padding-bottom: rem-calc(90);
      }

      @media only screen and (min-width: $media-md) {
        @include background-image(
          "../elements/items",
          "super-market-background.svg",
          no-repeat,
          center center,
          100%,
          100%,
          129vh,
          77vh
        );
        @include align-absolutePosition(18vh, 0, auto, 0);
      }

      button.item label {
        top: rem-calc(70);
        border-radius: rem-calc(6);
      }
    }

    .sign {
      padding: rem-calc(10);
      width: rem-calc(327);

      @media only screen and (max-width: $media-md) {
        margin: auto;
        margin-top: rem-calc(308);
      }

      @media only screen and (max-width: $media-md) {
        margin-top: rem-calc(270);
      }

      @media only screen and (max-width: $media-xs) {
        margin-top: rem-calc(210);
        width: 85%;
      }

      @media only screen and (min-width: $media-md) {
        @include align-absolutePosition(10vh, 63vh, auto, 0);
        width: 53vh;
        padding: 3vh;
      }

      color: $white;
      background: #0f6fe3;
      z-index: +1;

      p {
        font-family: "Ubuntu-Bold";
        font-size: $font-size--1;

        @media only screen and (max-width: $media-md) {
          font-size: rem-calc(18);
        }

        margin: 0;
        text-align: center;
        animation: tracking-in-expand 2s linear, text-glow 3s 1s linear infinite;
      }
    }

    .character-bag {
      @include align-absolutePosition(auto, -10vh, 0, 0);
      height: 55vh;
      width: 35vh;

      @media only screen and (max-width: $media-md) {
        position: static;
      }

      .Character {
        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(0, auto, -50%, 58%);
          z-index: +1;
        }
      }

      .bag {
        .item.plastic-bag {
          @extend %no-text;
          @include background-image(
            "../elements/items",
            "plastic-bag.svg",
            no-repeat,
            center center,
            100%,
            100%,
            10vh,
            16.2vh
          );
          @include align-absolutePosition(0, auto, -89%, 47.5%);
          z-index: +2;
        }

        .item.reusable-bag {
          @extend %no-text;
          @include background-image(
            "../elements/items",
            "reusable-bag.svg",
            no-repeat,
            center center,
            100%,
            100%,
            5vh,
            38.7vh
          );

          @include align-absolutePosition(0, auto, -32.7%, 57.8%);
          z-index: +2;

          @media only screen and (max-width: $media-sm) {
            top: rem-calc(-245);
          }
        }
      }
    }

    #open-character-modal:target .Character {
      @media only screen and (max-width: $media-md) {
        position: fixed;
      }
    }

    .bag-selection {
      @include align-absolutePosition(4.5vh, 0, 0, auto);
      width: 9vw;
      height: 5vh;
      z-index: +3;

      @media only screen and (max-width: $media-md) {
        width: rem-calc(81);
        height: rem-calc(40);
        top: rem-calc(20);
        left: 50%;
        margin-left: rem-calc(90);
        bottom: auto;
      }

      @media only screen and (max-width: $media-sm) {
        margin-left: rem-calc(65);
        top: rem-calc(85);
      }

      @media only screen and (max-width: $media-xs) {
        margin-left: rem-calc(30);
        top: rem-calc(-110);
      }

      button {
        display: block;
        background-size: cover;
        background-repeat: no-repeat;

        &:hover {
          filter: none;

          svg {
            filter: drop-shadow(3px 3px 0px $black);
          }
        }
      }

      button.reusable-bag-icon {
        @include align-absolutePosition(5%, 70%, auto, 0);
        animation: jello-horizontal 1.5s both infinite;
        width: 3vh;
        height: 4vh;
        svg {
          width: 100%;
          height: 100%;
        }

        &.selected {
          animation: none;
        }

        &:disabled {
          animation: none;
          &:not(.selected) {
            filter: grayscale(1);
          }
          svg {
            filter: none;
          }
          cursor: default;
        }
      }

      button.plastic-bag-icon {
        @include align-absolutePosition(5%, 0, auto, 0);
        animation: jello-horizontal 1.5s both 0.75s infinite;
        width: 3vh;
        height: 4vh;
        svg {
          width: 100%;
          height: 100%;
        }

        &.selected {
          animation: none;
        }

        &:disabled {
          animation: none;
          &:not(.selected) {
            filter: grayscale(1);
          }
          svg {
            filter: none;
          }
          cursor: default;
        }
      }
    }

    .shelves .item {
      margin: 0 !important;

      @media only screen and (max-width: $media-md) {
        position: relative !important;
        width: fit-content;
        margin-left: rem-calc(20) !important;
        margin-right: rem-calc(20) !important;
      }

      @media only screen and (max-width: $media-sm) {
        margin-left: rem-calc(4) !important;
        margin-right: rem-calc(4) !important;
      }

      &:before {
        margin: 0;
        @media only screen and (max-width: $media-md) {
          margin: auto !important;
        }
      }

      &.raw-fish {
        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(24) !important;
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(49vh, auto, auto, 28vh);
          label {
            top: 7vh;
          }
        }
        label {
          width: min-content;
        }
      }

      &.raw-beef {
        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(49vh, auto, auto, 4vh);
          label {
            top: 7vh;
          }
        }
        label {
          width: min-content;
        }
      }

      &.raw-chicken {
        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(49.5vh, auto, auto, 16vh);
          label {
            top: 6.5vh;
            width: min-content;
          }
        }
        label {
          width: min-content;
        }
      }

      &.whole-salad {
        @media only screen and (max-width: $media-sm) {
          &:before {
            background-position: center rem-calc(3) !important;
          }
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(21.5vh, auto, auto, 103vh);
          label {
            top: 2vh;
            right: -17vh;
            left: 10vh;
          }
        }
      }

      &.packaged-salad {
        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(35vh, auto, auto, 119vh);
          label {
            top: 8vh;
            right: 50%;
            left: 50%;
            width: min-content;
          }
        }
        label {
          width: min-content;
        }
      }

      &.seasonal-fruit {
        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(36vh, auto, auto, 103vh);
          label {
            top: 6.5vh;
            left: -11vh;
          }
        }
        label {
          width: min-content;
        }
      }

      &.exotic-fruit {
        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(45vh, auto, auto, 107vh);
          label {
            top: 8vh;
            right: 50%;
            left: 50%;
            transform: translate(-50%);
          }
        }
        label {
          width: min-content;
        }
      }

      &.ham {
        @media only screen and (max-width: $media-md) {
          &:before {
            width: rem-calc(62);
          }
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(35.5vh, auto, auto, 40vh);
          label {
            top: 5.2vh;
            right: 50%;
            left: 50%;
            transform: translate(-50%);
            width: min-content;
          }
        }

        &:before {
          background-size: auto;
          background-repeat: space;
        }

        z-index: +1;
      }

      &.tofu {
        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(26vh, auto, auto, 43vh);
          label {
            top: 5.8vh;
            right: 50%;
            left: 50%;
            transform: translate(-50%);
          }
        }

        &:before {
          background-size: auto;
          background-repeat: space;
        }

        label {
          width: min-content;
        }
      }

      &.hummus {
        @media only screen and (max-width: $media-sm) {
          min-width: rem-calc(72);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(19vh, auto, auto, 43vh);
          label {
            top: 3.8vh;
            right: 50%;
            left: 50%;
            transform: translate(-50%);
          }
        }

        &:before {
          background-size: auto;
          background-repeat: space;
        }
        label {
          width: min-content;
        }
      }

      &.precooked-pizza {
        @media only screen and (max-width: $media-md) {
          min-width: rem-calc(85);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(52.7vh, auto, auto, 39.5vh);
          label {
            top: 6.8vh;
            right: 50%;
            left: 50%;
            transform: translate(-50%);
          }
        }

        &:before {
          background-size: auto;
          background-repeat: space;
        }
        label {
          width: min-content;
        }
      }

      &.precooked-fries {
        @media only screen and (max-width: $media-sm) {
          min-width: rem-calc(95);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(44vh, auto, auto, 40vh);
          label {
            top: 5.5vh;
            right: 50%;
            left: 50%;
            transform: translate(-50%);
          }
        }

        &:before {
          background-size: auto;
          background-repeat: space;
        }
        label {
          width: max-content;

          @media only screen and (max-width: $media-md) {
            width: rem-calc(70);
          }
        }
      }

      &.leaveShelves {
        animation-name: leaveShelves;
      }
    }

    .inventory {
      position: relative;
      @media only screen and (min-width: $media-md) {
        @include background-image(
          "../elements/items",
          "super-market-cashier.svg",
          no-repeat,
          center center,
          100%,
          100%
        );
        @include align-absolutePosition(auto, 0, 0, 0);
        z-index: +2;
        height: 20vh;
        width: 65vw;
      }

      @media only screen and (max-width: $media-md) {
        width: 100%;
        height: rem-calc(191);
        @include align-absolutePosition(rem-calc(744), 0, auto, 0);
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(614);
      }

      @media only screen and (max-width: $media-xs) {
        top: rem-calc(636);
      }

      &:before {
        @media only screen and (max-width: $media-md) {
          @include background-image(
            "../elements/items",
            "super-market-cashier.svg",
            no-repeat,
            center center,
            100%,
            100%
          );

          height: rem-calc(191);
          width: rem-calc(944);
          margin-left: rem-calc(-750);
          left: 50%;
          top: rem-calc(-80);
          bottom: auto;
          position: absolute;
        }

        @media only screen and (max-width: $media-sm) {
          margin-left: rem-calc(-760);
          top: rem-calc(-15);
        }

        @media only screen and (max-width: $media-xs) {
          height: rem-calc(132);
          width: rem-calc(653);
          margin-left: rem-calc(-540);
          top: rem-calc(-175);
        }
      }

      .items {
        position: relative;
        width: auto;
        padding: 0;
        margin: auto auto auto 2vh;
        display: inherit;
        height: 14vh;
        gap: 3vh;
        display: flex;

        @media only screen and (max-width: $media-sm) {
          justify-content: center !important;
          &.has-more-before,
          &.has-more-after {
            justify-content: flex-start !important;
          }
        }

        @media only screen and (max-width: $media-md) {
          height: auto;
          min-height: rem-calc(100);
          max-height: rem-calc(299);
          margin: auto;
          margin-top: rem-calc(220);
          background-color: rgba($supermarket--1, 0.85);
          width: calc(88% - 4%);
          padding: 2%;
          overflow: auto;
          position: fixed;
          z-index: 7;
          top: auto;
          bottom: rem-calc(109);
          left: 5%;
        }

        @media only screen and (max-width: $media-md) {
          bottom: rem-calc(93);
        }

        .raw-beef:before {
          background-image: url(../elements/items/raw-beef-single.svg);
          width: 4.1vh;
          height: 4.6vh;
          @media only screen and (max-width: $media-md) {
            width: rem-calc(33);
            height: rem-calc(37);
          }
        }

        .raw-chicken:before {
          background-image: url(../elements/items/raw-chicken-single.svg);
          width: 5.2vh;
          height: 3.6vh;
          @media only screen and (max-width: $media-md) {
            width: rem-calc(42);
            height: rem-calc(29);
          }
        }

        .raw-fish:before {
          background-image: url(../elements/items/raw-fish-single.svg);
          width: 6.5vh;
          height: 3vh;
          @media only screen and (max-width: $media-md) {
            width: rem-calc(53);
            height: rem-calc(24);
          }
        }

        .whole-salad:before {
          width: 8vh;
          height: 6.5vh;

          @media only screen and (min-width: $media-sm) {
            background-image: url(../elements/items/whole-salad-full.svg);
          }

          @media only screen and (max-width: $media-md) {
            width: rem-calc(39);
            height: rem-calc(55);
          }
        }

        .ham:before {
          width: 7.5vh;
        }
        .tofu:before {
          width: 5vh;
        }
        .hummus:before {
          width: 3.7vh;
        }
        .precooked-pizza:before {
          width: 7.5vh;
        }
        .precooked-fries:before {
          width: 5vh;
        }

        // seasonal-fruit
        .seasonal-fruit:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(65);
            height: rem-calc(49);
          }
        }

        // ham
        .ham:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(57);
            height: rem-calc(57);
          }
        }

        // exotic-fruit
        .exotic-fruit:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(106);
            height: rem-calc(61);
          }
        }

        // tofu
        .tofu:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(57);
            height: rem-calc(57);
          }
        }

        // precooked-pizza
        .precooked-pizza:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(61);
            height: rem-calc(61);
          }
        }

        // hummus
        .hummus:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(32);
            height: rem-calc(45);
          }
        }

        // packaged-salad
        .packaged-salad:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(41);
            height: rem-calc(57);
          }
        }

        // precooked-fries
        .precooked-fries:before {
          @media only screen and (max-width: $media-md) {
            width: rem-calc(45);
            height: rem-calc(61);
          }
        }
      }
    }

    .inventory {
      .item {
        bottom: -5.5vh;
        animation: 250ms ease-in-out 0ms 1 normal forwards running enterInventory;

        @media only screen and (max-width: $media-md) {
          margin-left: 2%;
          margin-right: 2%;
          display: inline-block;
          margin-bottom: rem-calc(20);
          position: static;
          filter: drop-shadow(0 0 0 $black) !important;

          &:first-child {
            margin-left: 5%;
          }

          &:last-child {
            margin-right: 5%;
          }
        }

        @media only screen and (max-width: $media-sm) {
          margin-left: 0;
          margin-right: 0;

          &:first-child {
            margin-left: rem-calc(16);
          }

          &:last-child {
            margin-right: rem-calc(16);
          }
        }
      }
    }

    .item {
      &.selected {
        bottom: 0;
      }
    }
  }

  // has-more after & before
  .has-more-after:after {
    @media only screen and (max-width: $media-md) {
      right: rem-calc(-32);
    }
  }

  .inventory .has-more-after:after {
    @media only screen and (max-width: $media-md) {
      right: rem-calc(-13);
    }
  }

  .has-more-before:before,
  .has-more-after:after {
    background-color: $supermarket--1;
  }
}

// remove drop shadow for IOS
.Supermarket .primary-items .inventory .items .item {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media only screen and (max-width: $media-md) {
      animation: auto !important;
      position: relative;
      margin-top: rem-calc(-65);
    }
  }

  /* Detect Mac Safari 6.1 or newer [OS X 10.7 or Newer], hide any straggling Chrome references */
  @media screen and (-webkit-max-device-pixel-ratio: 1) and (min-color-index: 0) {
    @media only screen and (max-width: $media-md) {
      animation: auto !important;
      position: relative;
      margin-top: rem-calc(-65);
    }
  }
}

// General rules for all items of supermarket
.App .scene.Supermarket button.item {
  @media only screen and (max-width: $media-md) {
    min-width: rem-calc(122) !important;
    max-width: rem-calc(122) !important;
    margin-left: rem-calc(4) !important;
    margin-right: rem-calc(4) !important;
    label {
      width: calc(100% - rem-calc(12)) !important;
      padding: rem-calc(6);
    }
  }
}

// General rules for all items of supermarket - inventory
.App .scene.Supermarket .inventory button.item {
  min-width: rem-calc(48) !important;
  max-width: rem-calc(48) !important;

  // whole-salad - item
  &.whole-salad:before {
    background-position: center rem-calc(5) !important;
  }
}
