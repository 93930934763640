@import "global.scss";

.Timeline.character-set-up ol:before {
  @media only screen and (max-width: $media-md) {
    width: 102%;
    left: 0;
  }
}

.CharacterSetUp {
  background: $grey-1;

  &:before {
    @media only screen and (max-width: $media-md) {
      background: $grey-1;
      content: "";
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      position: fixed;
    }
  }

  @media only screen and (max-width: $media-sm) and (min-height: $media-sm) {
    padding-bottom: 0 !important;
    height: 100%;
  }

  aside.smallOptions {
    @include align-absolutePosition(0, auto, 0, 0);

    width: 50%;
    height: auto;

    @media only screen and (max-width: $media-md) {
      top: rem-calc(120);
      max-width: rem-calc(200);
      left: 50%;
      margin-left: rem-calc(-230);
      bottom: auto;
      height: rem-calc(230);
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(88);
      max-width: rem-calc(130);
      margin-left: rem-calc(-150);
      height: rem-calc(180);
    }

    @media only screen and (min-width: $media-md) {
      width: 50vw;
      height: 29vh;
    }

    @media only screen and (max-width: $media-sm) and (min-height: $media-sm) {
      top: 50%;
      margin-top: rem-calc(-250);
    }

    @media only screen and (max-width: $media-xs) and (min-height: $media-sm) {
      margin-top: rem-calc(-210);
    }

    > div {
      max-width: rem-calc(250);
      margin: auto;

      @media only screen and (min-width: $media-md) {
        @include align-absolutePosition(0, 30%, 0, auto);
        width: 30vh;
      }
    }
  }

  aside.bigOptions {
    @include align-absolutePosition(0, 0, 0, auto);

    width: 50%;
    height: auto;

    @media only screen and (max-width: $media-md) {
      top: rem-calc(115);
      bottom: auto;
      max-width: rem-calc(200);
      right: 50%;
      margin-right: rem-calc(-230);
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(83);
      max-width: rem-calc(130);
      margin-right: rem-calc(-150);
    }

    @media only screen and (min-width: $media-md) {
      width: 50vw;
      height: 29vh;
    }

    @media only screen and (max-width: $media-sm) and (min-height: $media-sm) {
      top: 50%;
      margin-top: rem-calc(-250);
    }

    @media only screen and (max-width: $media-xs) and (min-height: $media-sm) {
      margin-top: rem-calc(-210);
    }

    > div {
      max-width: rem-calc(250);
      margin: auto;

      @media only screen and (min-width: $media-md) {
        @include align-absolutePosition(0, auto, 0, 30%);
        width: 30vh;
      }
    }
  }

  main {
    @include align-absolutePosition(10vh, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      z-index: 1;
      bottom: auto;
      top: rem-calc(320);
      text-align: center;
    }

    @media only screen and (min-width: $media-sm) and (max-width: $media-md) {
      transform: scale(0.7);
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(300);
    }

    width: 100%;
    @media only screen and (max-width: $media-md) {
      padding-bottom: rem-calc(32);
    }

    @media only screen and (min-width: $media-md) {
      width: 11.5vw;
      height: 70vh;
    }

    @media only screen and (max-width: $media-xs) {
      top: rem-calc(260);
    }

    @media only screen and (max-width: $media-sm) and (min-height: $media-sm) {
      top: 50%;
      margin-top: rem-calc(-40);
    }

    .Character {
      margin: auto;

      @media only screen and (max-width: $media-md) {
        margin-left: rem-calc(-259);
        left: 50%;
      }

      @media only screen and (max-width: $media-sm) {
        margin-left: rem-calc(-129);
      }

      @media only screen and (max-width: $media-xs) {
        transform: scale(0.7);
        margin-left: rem-calc(-123);
      }
    }

    // adult
    .Character.adult {
      @media only screen and (max-width: $media-md) {
        width: rem-calc(139);
        height: rem-calc(441);
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(75);
        height: rem-calc(240);
      }
    }

    div.adult:before {
      @include background-image(
        "../elements/blobs",
        "blob-character-set-up.svg",
        no-repeat,
        center center,
        100%,
        100%,
        54vh,
        78vh
      );

      @media only screen and (max-width: $media-md) {
        width: rem-calc(350);
        height: rem-calc(520);
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(190);
        height: rem-calc(328);
      }

      @include align-absolutePosition(0, 0, 0, -66%);
    }

    // child
    .Character.child {
      @media only screen and (max-width: $media-md) {
        width: rem-calc(134);
        height: rem-calc(406);
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(64);
        height: rem-calc(198);
      }
    }

    div.child:before {
      @include background-image(
        "../elements/blobs",
        "blob-child.svg",
        no-repeat,
        center center,
        100%,
        100%,
        40vh,
        72vh
      );
      @include align-absolutePosition(0, 0, -10%, -65%);

      @media only screen and (max-width: $media-md) {
        width: rem-calc(300);
        height: rem-calc(480);
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(138);
        height: rem-calc(245);
      }
    }

    button {
      @extend %white-button;
      display: block;
      margin: 0 auto;
      margin-top: rem-calc(28);
      width: rem-calc(142);

      @media only screen and (min-width: $media-md) {
        margin-top: 4vh;
        width: 20vh;
      }

      @media only screen and (max-width: $media-md) {
        margin-left: rem-calc(110);
        top: rem-calc(-278);
        left: 50%;
      }

      @media only screen and (max-width: $media-sm) {
        margin-left: rem-calc(-20);
        top: rem-calc(-168);
      }

      @media only screen and (max-width: $media-xs) {
        top: rem-calc(-188);
      }

      position: relative;
    }
  }
}
