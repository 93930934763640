@import "global.scss";
@import "common/items.scss";
@import "common/Animations.scss";

// Timeline only for transportation
.App .Timeline.transportation {
  @media only screen and (max-width: $media-md) {
    z-index: 3;
  }
}

// Transportation scene
.Transportation {
  background: $blue-1;

  @media only screen and (max-width: $media-md) {
    height: rem-calc(1145);
  }

  @media only screen and (max-width: $media-sm) {
    height: rem-calc(1200);
  }

  &:before {
    @include background-image("../elements/blobs", "blob-transport.svg", no-repeat, center center, 80%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      background-size: rem-calc(646) rem-calc(347);
      background-position: center rem-calc(20);
      @include align-absolutePosition(0, 0, rem-calc(-288), 0);
      background-color: $blue-1;
    }
  }

  .primary-elements {
    @include align-absolutePosition(0, 0, 0, 0);
    width: 85vw;
    height: 85vh;

    @media only screen and (max-width: $media-md) {
      width: 100%;
      height: 100%;
    }

    .item.thrash-can {
      @include background-image(
        "../elements/items",
        "transport-bin-empty.svg",
        no-repeat,
        center center,
        100%,
        100%,
        12vh,
        16.5vh
      );
      @include align-absolutePosition(auto, 105vh, 1vh, auto);
      z-index: +2;

      @media only screen and (max-width: $media-md) {
        left: 50%;
        right: auto;
        margin-left: rem-calc(-255);
        top: rem-calc(960) !important;
        bottom: auto;
        z-index: +1;
        width: rem-calc(88);
        height: rem-calc(121);
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(1000) !important;
        margin-left: rem-calc(-55);
      }
    }

    .item.thrash-can.full {
      @include background-image(
        "../elements/items",
        "transport-bin-full.svg",
        no-repeat,
        center center,
        100%,
        100%,
        12vh,
        16.5vh
      );

      @include align-absolutePosition(auto, 105vh, 1vh, auto);
      z-index: +2;

      @media only screen and (max-width: $media-md) {
        left: 50%;
        right: auto;
        margin-left: rem-calc(-255);
        top: rem-calc(960) !important;
        bottom: auto;
        width: rem-calc(88);
        height: rem-calc(121);
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(1000) !important;
        margin-left: rem-calc(-55);
      }
    }

    .Character {
      @include align-absolutePosition(auto, 0, -3vh, 74vh);
      width: 12.1vh;
      height: 38vh;
      z-index: +2;

      &:before {
        @include background-image(
          "../elements/blobs",
          "character-breakfast-dinner-cooking-shadow.svg",
          no-repeat,
          center center,
          100%,
          100%,
          17vh,
          5.5vh
        );

        @include align-absolutePosition(auto, 10%, -4.5%, -20%);
      }

      &.child {
        width: 11.1vh;
        height: 34vh;
        &:before {
          left: -26%;
        }
      }
    }

    .road {
      @include background-image(
        "../elements/items",
        "transport-background.svg",
        no-repeat,
        center center,
        100%,
        100%,
        129vh,
        77vh
      );

      @include align-absolutePosition(7vh, 0, auto, 0);
      z-index: +1;
      background-size: cover;

      @media only screen and (max-width: $media-md) {
        background-image: none;
        top: rem-calc(70);
        height: 100%;
        width: 100%;
        z-index: 0;
      }

      .item {
        // label - for all transportation page
        label {
          @media only screen and (max-width: $media-md) {
            font-size: rem-calc(14);
          }
        }

        // walk
        &.walk {
          @include align-absolutePosition(auto, auto, 23.1vh, 2vh);
          z-index: +1;

          @media only screen and (max-width: $media-md) {
            bottom: auto;
            top: rem-calc(20);
            left: 50%;
            margin-left: rem-calc(-146) !important;
          }

          @media only screen and (max-width: $media-sm) {
            top: rem-calc(9);
          }

          label {
            width: min-content;
            top: rem-calc(66);

            @media only screen and (min-width: $media-md) {
              top: 16vh;
            }

            @media only screen and (max-width: $media-md) {
              margin-left: rem-calc(110);
              width: rem-calc(150);
            }
          }
        }

        &.walk.selected {
          animation: vibrate-1 1s linear;
          transform-origin: 50% 50%;

          &:before {
            background-image: url("../elements/items/walk-selected.svg");
          }
        }

        // bicycle
        &.bicycle {
          @include align-absolutePosition(auto, auto, 23vh, 21.5vh);
          z-index: +1;

          @media only screen and (max-width: $media-md) {
            margin-left: rem-calc(-2);
            top: rem-calc(235);
            right: auto;
            bottom: auto;
            left: 50%;
          }

          @media only screen and (max-width: $media-sm) {
            top: rem-calc(195);
          }

          &:before {
            @media only screen and (max-width: $media-md) {
              left: rem-calc(50);
              position: relative;
            }
          }

          label {
            top: 15vh;

            @media only screen and (max-width: $media-md) {
              top: rem-calc(40);
              right: auto;
              left: rem-calc(-135);
              width: rem-calc(150);
            }
          }
        }

        &.bicycle.selected {
          animation: vibrate-1 1s linear;
          transform-origin: 50% 50%;
        }

        // scooter motorbike
        &.scooter-or-motorbike {
          @include align-absolutePosition(auto, auto, 23vh, 60.5vh);
          z-index: +2;

          @media only screen and (max-width: $media-md) {
            top: rem-calc(690);
            bottom: auto;
            left: 50%;
            margin-left: rem-calc(-75);
          }

          @media only screen and (max-width: $media-sm) {
            top: rem-calc(620);
          }

          &:before {
            position: relative;
            @media only screen and (max-width: $media-md) {
              left: rem-calc(-62);
            }
          }

          label {
            @media only screen and (max-width: $media-md) {
              top: rem-calc(40);
              margin-left: rem-calc(32);
            }
            @media only screen and (min-width: $media-md) {
              top: 16vh;
            }
            width: min-content;
          }
        }

        &.scooter-or-motorbike.selected {
          animation: vibrate-1 1s linear;
          transform-origin: 50% 50%;
          &:before {
            background-image: url("../elements/items/scooter-or-motorbike-selected.svg");
          }
        }

        // Car
        &.car {
          @include align-absolutePosition(auto, auto, 23vh, 73vh);
          z-index: +1;

          @media only screen and (max-width: $media-md) {
            top: rem-calc(856);
            bottom: auto;
            left: 50%;
            margin-left: rem-calc(-58);
            padding-bottom: rem-calc(160);
          }

          @media only screen and (max-width: $media-sm) {
            top: rem-calc(769);
          }

          &:before {
            @media only screen and (max-width: $media-md) {
              left: rem-calc(60);
              position: relative;
            }
          }

          label {
            top: 15vh;
            width: min-content;

            @media only screen and (max-width: $media-md) {
              top: rem-calc(80);
              bottom: auto;
              margin-right: rem-calc(60);
              width: rem-calc(135);
            }
          }
        }

        &.car.selected {
          animation: vibrate-1 1s linear;
          transform-origin: 50% 50%;

          &:before {
            background-image: url("../elements/items/car-selected.svg");
          }
        }

        &.car:after {
          @media only screen and (max-width: $media-md) {
            background-image: url("../elements/items/plant.svg");
            content: "";
            width: rem-calc(196);
            height: rem-calc(100);
            position: absolute;
            left: rem-calc(-150);
            top: 0;
            z-index: -1;
            background-repeat: no-repeat;
          }
        }

        // public-transport
        &.public-transport {
          @include align-absolutePosition(auto, auto, 23vh, 2.5vh);

          @media only screen and (max-width: $media-md) {
            margin-left: rem-calc(-200);
            left: 50%;
            top: rem-calc(390);
            right: auto;
            bottom: auto;
          }

          @media only screen and (max-width: $media-sm) {
            top: rem-calc(310);
          }

          label {
            top: 36vh;
            left: 36.5vh;

            @media only screen and (max-width: $media-md) {
              left: 50%;
              margin-left: rem-calc(-138);
              top: rem-calc(100);
              white-space: nowrap;
              width: rem-calc(250);
            }

            margin: 0;
            width: min-content;
          }
        }

        &.public-transport.selected {
          animation: vibrate-1 1s linear;
          transform-origin: 50% 50%;
          &:before {
            background-image: url("../elements/items/public-transport-selected.svg");
          }
        }
        &:not(.selected):disabled {
          filter: grayscale(100%) contrast(50%) brightness(140%);
        }
      }
    }
  }

  button.trash {
    border: 0;
    background: none;
    z-index: +2;
    @include align-absolutePosition(auto, -10vh, 0, 0);
    width: 16vh;
    height: 4.5vh;

    @media only screen and (max-width: $media-md) {
      width: rem-calc(104);
      height: rem-calc(29);
      right: 50%;
      margin-right: -100px;
      bottom: auto;
      top: rem-calc(750);
      left: auto;
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(670);
    }

    transition: right 0.25s ease-in-out;

    &:hover {
      filter: none;
      svg {
        filter: drop-shadow(3px 3px 0px $transport--1);
      }
    }

    svg {
      opacity: 1;
      width: 100%;
      height: 100%;
      #can {
        animation: brightness 2s ease-in-out infinite;
        animation-delay: 3s;
      }
    }

    &:disabled {
      opacity: 0;
      svg {
        opacity: 0;
      }
    }
  }
}

// z-index - for mobile fixes
.App .Transportation.scene .Battery {
  @media only screen and (max-width: $media-md) {
    z-index: 4;
    height: rem-calc(210);
    top: auto;
    left: rem-calc(45);
    bottom: rem-calc(-66);
  }
}

.App .Transportation.scene:after {
  @media only screen and (max-width: $media-md) {
    z-index: 3 !important;
  }
}

//fi - lang
// ---------------------------------------------------------------------------------
html:lang(fi) {
  // bicycle css rules only for for -- fi
  .Transportation .primary-elements .road .item.bicycle {
    @media only screen and (min-width: $media-md) {
      left: 16.1vh;
      width: 8vw;
    }
  }

  .Transportation .primary-elements .road .item.scooter-or-motorbike label {
    @media only screen and (min-width: $media-md) {
      width: auto;
      word-break: break-word;
    }

    @media only screen and (max-width: $media-md) {
      margin-left: rem-calc(10);
    }
  }
  .Transportation .primary-elements .road .item.public-transport label {
    @media only screen and (min-width: $media-md) {
      left: 33.5vh;
    }
  }
}

//lv - lang
// ---------------------------------------------------------------------------------
html:lang(lv) {
  // public-transport
  .Transportation .primary-elements .road .item.public-transport label {
    @media only screen and (min-width: $media-md) {
      width: 26%;
      left: 48%;
    }
  }

  // scooter-or-motorbike
  .Transportation .primary-elements .road .item.scooter-or-motorbike label {
    @media only screen and (min-width: $media-md) {
      left: -2vw;
    }
  }
}

//et - lang
// ---------------------------------------------------------------------------------
html:lang(et) {
  // bicycle
  .Transportation .primary-elements .road .item.bicycle label {
    @media only screen and (min-width: $media-md) {
      width: 90%;
      left: -8%;
    }
  }

  // public-transport
  .Transportation .primary-elements .road .item.public-transport label {
    @media only screen and (min-width: $media-md) {
      width: 26%;
      left: 48%;
      margin-top: 2.5%;
    }
  }

  // scooter-or-motorbike
  .Transportation .primary-elements .road .item.scooter-or-motorbike label {
    @media only screen and (min-width: $media-md) {
      left: -2.2vw;
      left: -44%;
    }
  }
}
