@import "global.scss";

// Note that common modal CSS is implemented in App.scss.
.Badges {
  .items {
    height: fit-content;
    @media only screen and (min-width: $media-md) {
      height: 75vh;
    }
    background-color: $white;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .badge {
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(24);
    flex-basis: 90%;

    @media only screen and (min-width: $media-sm) {
      flex-basis: 42%;
    }

    @media only screen and (min-width: $media-md) {
      flex-basis: 22%;
    }

    text-align: center;

    &:before {
      margin: 0 auto;
      display: block;
      content: " ";
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;
      filter: brightness(0);
      opacity: 0.09;
    }

    h3 {
      text-decoration: line-through 1.2rem;
      color: $character-0;
    }

    p {
      text-decoration: line-through 1rem;
      color: $character-0;
    }

    &.owns {
      h3,
      p {
        text-decoration: none;
        color: $grey--1;
      }

      &:before {
        filter: none;
        opacity: 1;
      }
    }
  }
}

// prettier-ignore
$badges-svgs: (
  water-conservation-master:  ( width: 12.5vh, height: 15vh),
  clean-street-wizard:        ( width: 13.5vh, height: 15vh),
  eco-ninja:                  ( width: 15vh, height: 15vh), 
  energy-saving-expert:       ( width: 13.5vh, height: 15vh),
  low-waste-champion:         ( width: 12.5vh, height: 15vh),
  responsible-shopper:        ( width: 14.5vh, height: 15vh),
  recycling-captain:          ( width: 12.5vh, height: 15vh)
);

@each $name, $code in $badges-svgs {
  .badge.#{$name}:before {
    width: map-get($code, width);
    height: map-get($code, height);
    background-image: url("../elements/badges/#{$name}.svg");
  }
}
