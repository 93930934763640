@import "global.scss";
@import "common/Animations.scss";

.Intro {
  @media only screen and (max-width: $media-md) {
    padding-bottom: 0 !important;
  }

  @media only screen and (max-height: $media-sm) and (max-width: $media-sm) {
    height: rem-calc(610);
  }

  @media only screen and (max-height: $media-sm-height) and (max-width: $media-sm) {
    height: rem-calc(450);
  }

  &:before {
    @include background-image("../elements/blobs", "blob-intro.svg", no-repeat, center center, 95vh, 70vh);
    @include align-absolutePosition(30vh, 0, 0, 0);
  }

  header {
    margin: 0 auto;
    position: relative;
    width: 100%;

    @media only screen and (min-width: $media-md) {
      max-width: 120vh;
    }

    // Logo
    &:before {
      display: block;
      content: " ";
      @include align-absolutePosition(5vh, 0, 0, 5vh);

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(76);
        height: rem-calc(51);
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(16);
        left: rem-calc(24);
      }
      margin: 0;
      width: 13vh;
      height: 9vh;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @each $eu-lang in $eu-langs {
      &:lang(#{$eu-lang}):before {
        background-image: url("../elements/eu-logos/logo_#{$eu-lang}.png");
      }
    }

    h1 {
      @include align-absolutePosition(2vh, 0, auto, 0);
      @include get-icon(before, $common-icons, logo, $grey, 20vh, 1, false);
      animation: tracking-in-expand 4s linear;
      text-align: center;
      text-transform: uppercase;
      color: $grey;

      @media only screen and (max-width: $media-sm) {
        top: 23vh;
        font-size: rem-calc(18);
      }

      @media only screen and (max-height: $media-sm-height) and (max-width: $media-sm) {
        top: rem-calc(34);
      }

      &:before {
        display: block;

        @media only screen and (max-width: $media-sm) {
          font-size: rem-calc(110);

          @media only screen and (max-height: $media-sm-height) and (max-width: $media-sm) {
            font-size: rem-calc(80);
          }

          margin-bottom: rem-calc(10);
        }
      }
    }

    .LanguageSelector {
      @include align-absolutePosition(2vh, 2vh, auto, auto);

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(-17);
        right: rem-calc(24);
      }

      h2 {
        margin: 0;
      }
    }
  }

  // Start button
  button {
    animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 2.7s;

    @include align-absolutePosition(0px, 0px, 5vh, 0px);
    text-transform: uppercase;
    width: rem-calc(131);
    height: rem-calc(59);

    @media only screen and (max-width: $media-sm) {
      font-size: rem-calc(18);
      @include align-absolutePosition(52vh, 0, auto, 0);
    }

    @media only screen and (max-height: $media-sm-height) and (max-width: $media-sm) {
      top: rem-calc(174);
    }

    @media only screen and (min-width: $media-sm) {
      width: 31vh;
      height: 13.5vh;
    }

    background: $white;
    border-radius: 2vh;
    box-shadow: -0.5vh 0.5vh 0 #c4c4c4;
  }

  svg {
    @include align-absolutePosition(auto, 0, 0, 0);

    @media only screen and (max-height: $media-sm) and (max-width: $media-sm) {
      top: rem-calc(239);
      bottom: auto;
    }

    @media only screen and (max-height: $media-sm-height) and (max-width: $media-sm) {
      top: rem-calc(90);
    }

    // General animation variables
    $character-delay: 1.05s;
    $number-duration: 1.5s;

    // Green Two
    $two-green-delay: 0.4s;
    $two-green-woman-delay: $two-green-delay + $character-delay;
    $two-green-woman-duration: 0.75s;
    $two-green-plant-delay: $two-green-woman-delay + $two-green-woman-duration - 0.1s;
    $two-green-plant-duration: 0.4s;

    #two-green {
      animation: bounce-in-top $number-duration both;
      animation-delay: $two-green-delay;
    }

    #two-green-woman-watering {
      animation: slide-in $two-green-woman-duration cubic-bezier(0.25, 0.46, 0.45, 0.94) $two-green-woman-delay both;
      --translateX: 10%;
    }

    #two-green-plant {
      animation: slide-in $two-green-plant-duration cubic-bezier(0.25, 0.46, 0.45, 0.94) $two-green-plant-delay both;
      --translateY: -6%;
    }

    #two-green-window-glow {
      animation: blink-2 2.5s both infinite;
    }

    #two-green-flower-pot {
      animation: shake-tr 8s linear both infinite;
      transform-origin: top center;
    }

    // Orange Zero
    $zero-orange-delay: 0.9s;
    $zero-orange-man-delay: $zero-orange-delay + $character-delay;
    $zero-orange-man-duration: 0.7s;
    $zero-orange-machine-delay: $zero-orange-man-delay + $zero-orange-man-duration - 0.1s;
    $zero-orange-machine-duration: 0.5s;

    #zero-orange {
      animation: bounce-in-top $number-duration both;
      animation-delay: $zero-orange-delay;
    }

    #zero-orange-man {
      animation: slide-in $zero-orange-man-duration cubic-bezier(0.25, 0.46, 0.45, 0.94) $zero-orange-man-delay both;
      --translateX: 10%;
    }

    #zero-orange-machine-lights {
      animation: flicker 5s 2s linear both infinite;
    }

    #zero-orange-machine-light-bar {
      animation: flicker 1.6s 2.5s linear both infinite;
    }

    #zero-orange-coffee-sign {
      animation: slide-in $zero-orange-machine-duration cubic-bezier(0.25, 0.46, 0.45, 0.94) $zero-orange-machine-delay
          both,
        flicker 3s 3s linear both infinite;
      --translateY: 10%;
    }

    #zero-orange-pump {
      animation: vibrate-1 0.6s linear infinite both;
      transform-origin: 50% 50%;
      animation-delay: 1s;
    }

    // Purple Five
    $five-purple-delay: 1.1s;
    $five-purple-man-delay: $five-purple-delay + $character-delay - 0.1s;
    $five-purple-man-duration: 0.5s;
    $five-purple-robot-delay: $five-purple-man-delay + $five-purple-man-duration - 0.3s;
    $five-purple-robot-duration: 0.4s;

    #five-purple {
      animation: bounce-in-top $number-duration both;
      animation-delay: $five-purple-delay;
    }

    #five-purple-sitting-man-arm,
    #five-purple-sitting-man {
      animation: slide-in $five-purple-man-duration cubic-bezier(0.25, 0.46, 0.45, 0.94) $five-purple-man-delay both;
      --translateY: -10%;
    }

    #five-purple-robot {
      animation: slide-in $five-purple-robot-duration cubic-bezier(0.25, 0.46, 0.45, 0.94) $five-purple-robot-delay both,
        pulsate-bck 1.25s ease-in-out infinite both;
      --translateX: 8%;
    }

    #five-purple-robot-hand {
      animation: scale-in-hor-left 0.5s 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both, wobble 6s 3s linear infinite;
    }

    #five-purple-robot-hand-small {
      animation: wobble 6s 3.5s linear infinite;
    }

    // Red Zero
    $red-zero-delay: 1.3s;
    $red-zero-man-woman-delay: $red-zero-delay + $character-delay + 0.1s;
    $red-zero-man-woman-duration: 0.6s;

    #zero-red {
      animation: bounce-in-top $number-duration both;
      animation-delay: $red-zero-delay;
    }

    #zero-red-man-woman {
      animation: slide-in $red-zero-man-woman-duration cubic-bezier(0.25, 0.46, 0.45, 0.94) $red-zero-man-woman-delay
        both;
      --translateX: -10%;
    }

    //Mobile styling
    @media only screen and (min-width: 0) and (max-width: $media-sm) {
      transform: scale(0.36);
      bottom: rem-calc(-170);
      left: 50%;
      margin-left: rem-calc(-492);
    }

    @media only screen and (min-width: $media-sm) and (max-width: $media-md) {
      transform: scale(0.6);
      bottom: rem-calc(-105);
      left: 50%;
      margin-left: rem-calc(-492);
    }

    // for small browser height
    @media only screen and (max-height: $media-sm) {
      transform: scale(0.36);
      bottom: rem-calc(-170);
      left: 50%;
      margin-left: rem-calc(-492);
    }
  }
}
