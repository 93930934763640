@import "global.scss";
@import "common/items.scss";
@import "common/Animations.scss";

.Breaktime {
  background: $breaktime-1;

  @media only screen and (max-width: $media-md) {
    height: rem-calc(1285);
  }

  &:before {
    @include background-image("../elements/blobs", "blob-breaktime.svg", no-repeat, center center, 80%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      background-size: rem-calc(563) rem-calc(349);
      height: rem-calc(349);
      top: rem-calc(159);
      bottom: auto;
    }
  }

  .background-elements {
    @include align-absolutePosition(rem-calc(212), auto, auto, 0);
    width: 100%;
    height: rem-calc(212);

    @media only screen and (min-width: $media-md) {
      @include align-absolutePosition(0, auto, 0, 0);
      width: 50vw;
      height: 70vh;
    }

    @media only screen and (max-width: $media-md) {
      top: rem-calc(150);
    }

    .item.shelves-poster {
      @include background-image(
        "../elements/items",
        "breaktime-shelves-poster.svg",
        no-repeat,
        center center,
        100%,
        100%,
        45vh,
        37vh
      );
      @include align-absolutePosition(10%, 30%, auto, auto);

      @media only screen and (max-width: $media-md) {
        width: rem-calc(264);
        height: rem-calc(212);
        left: 50%;
        right: auto;
        bottom: auto;
        top: 0;
        margin-left: rem-calc(-164);
      }
    }
  }

  .primary-elements {
    @include align-absolutePosition(rem-calc(440), 0, auto, 0);
    width: 100%;
    height: rem-calc(457);

    @media only screen and (min-width: $media-md) {
      @include align-absolutePosition(0, 0, 0, 0);
      width: 75vw;
      height: 70vh;
    }

    @media only screen and (max-width: $media-md) {
      top: rem-calc(380);
    }

    .item.window {
      @media only screen and (max-width: $media-md) {
        width: 100%;
        height: rem-calc(500);
        @include align-absolutePosition(rem-calc(-50), auto, auto, 0);
      }

      @media only screen and (min-width: $media-md) {
        @include align-absolutePosition(auto, 0, 0, auto);
        @include background-image(
          "../elements/items",
          "breaktime-window.svg",
          no-repeat,
          center center,
          100%,
          100%,
          71vh,
          71vh
        );
      }
      z-index: +1;
    }

    .item {
      &.watching-tv {
        @include align-absolutePosition(0, auto, auto, 50%);

        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-132);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(auto, auto, -2vh, -67vh);
        }

        z-index: +1;
        label {
          top: 16vh;
          @media only screen and (max-width: $media-md) {
            top: rem-calc(162);
            left: 50%;
            right: auto;
            width: rem-calc(260);
            margin-left: rem-calc(-140);
          }
          width: min-content;
        }
      }

      &.doing-sports {
        @include align-absolutePosition(rem-calc(230), auto, auto, 50%);

        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-115);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(22vh, 15vh, auto, auto);
        }

        z-index: +1;
        label {
          top: rem-calc(50);
          width: rem-calc(150);

          @media only screen and (max-width: $media-md) {
            margin-left: rem-calc(-20);
          }

          @media only screen and (min-width: $media-md) {
            top: 16vh;
            width: min-content;
          }
        }
      }

      &.playing-outside {
        @include align-absolutePosition(rem-calc(650), auto, auto, 50%);

        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-155);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(40.4vh, 3vh, auto, auto);
        }
        z-index: +1;
        label {
          top: rem-calc(90);
          width: rem-calc(200);
          @media only screen and (max-width: $media-md) {
            margin-left: rem-calc(-135);
            left: 50%;
          }

          @media only screen and (min-width: $media-md) {
            top: 13vh;
            width: min-content;
          }
        }
      }

      &.charity-work {
        @include align-absolutePosition(rem-calc(460), auto, auto, 50%);

        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-140);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(31vh, 49vh, auto, auto);
        }

        z-index: +1;

        label {
          @media only screen and (max-width: $media-md) {
            left: 50%;
            right: auto;
            margin-left: rem-calc(-40);
            width: rem-calc(198);
          }

          top: rem-calc(128);

          @media only screen and (min-width: $media-md) {
            top: 19vh;
          }
        }
      }

      &:not(.selected):disabled {
        filter: grayscale(100%) contrast(50%) brightness(140%);
      }
    }
  }

  button.light {
    @include align-absolutePosition(rem-calc(-318), auto, auto, 50%);

    @media only screen and (max-width: $media-md) {
      margin-left: rem-calc(42);
    }

    width: rem-calc(58);
    height: rem-calc(138);

    @media only screen and (min-width: $media-md) {
      @include align-absolutePosition(0, 0, auto, -31vh);
      width: 10vh;
      height: 25vh;
    }

    svg {
      height: 100%;
      width: 100%;

      .ray,
      .bulb {
        animation: brightness-scale 2s ease-in-out infinite;
        animation-delay: 3s;
      }
    }

    &:disabled {
      .bulb {
        fill: $grey;
      }

      .ray {
        display: none;
      }
    }
  }
}
