@import "global.scss";

// Item Selection Animations

// Enter Table

@keyframes enterTable {
  0% {
    opacity: 0;
    margin-top: -10em;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

// Leave Table

@keyframes leaveTable {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 10em;
  }
}

//Enter Inventory

@keyframes enterInventory {
  0% {
    opacity: 0;
    margin-top: -10em;
  }
  100% {
    opacity: 1;
    margin-top: 0;
    transform: translate(0, 2vh);
    filter: drop-shadow(0 0 10px $black);
  }
}

//Shake Animation - Used in Footer buttons

@keyframes shake {
  10%,
  90% {
    transform: translate(-1px, -1px);
  }

  20%,
  80% {
    transform: translate(2px, 2px);
  }

  30%,
  50%,
  70% {
    transform: translate(-4px, -4px);
  }

  40%,
  60% {
    transform: translate(4px, 4px);
  }
}

// Glow Brightness - Used in Transport Easter Egg

@keyframes brightness {
  0%,
  100% {
    transform: scale(100%);
    filter: contrast(100%);
    transform-origin: center center;
  }
  50% {
    transform: scale(105%);
    filter: contrast(150%);
    transform-origin: center center;
  }
}

// Scale & Fade In (Top Left)  - Used in Lunch Easter Egg

@keyframes scale-in-tl {
  0% {
    transform: scale(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

// Scale & Glow - Used in Breaktime Easter Egg

@keyframes brightness-scale {
  0%,
  100% {
    filter: brightness(none);
    transform: scale(100%);
    transform-origin: top center;
  }
  50% {
    filter: brightness(200%);
    transform: scale(101%);
    transform-origin: top center;
  }
}

// Swing Top + Forward - Used Dinner Selection Map

@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

//Bounce In From Top - Used Dinner Selection Map Pins

@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

// Scale Up From Center - Used in Cooking Dinner Easter Egg

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// Battery Animations

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes wobble {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-20px) rotate(-6deg);
  }
  30% {
    transform: translateX(10px) rotate(6deg);
  }
  45% {
    transform: translateX(-10px) rotate(-3.6deg);
  }
  60% {
    transform: translateX(6px) rotate(2.4deg);
  }
  75% {
    transform: translateX(-4px) rotate(-1.2deg);
  }
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

// Rotate - Used in Snacking Easter Egg

@keyframes rotate {
  0% {
    transform: rotate(0);
    transform-origin: 50% 50%;
  }
  25% {
    transform: rotate(-12deg);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
}

// Slip Forward - Used in Intro Button and the "Healthy New World" ships

@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

// Simple fade in - Used in the Worlds Scenes

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Simple Vibration - Used in the Worlds Scenes

@keyframes vibrate-1 {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(0, 1px);
  }
  40% {
    transform: translate(0, -1px);
  }
  60% {
    transform: translate(0, 1px);
  }
  80% {
    transform: translate(0, -1px);
  }
  100% {
    transform: translate(0);
  }
}

// Text Animation - Used in Intro and Super Market Scenes

@keyframes tracking-in-expand {
  0%,
  79% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  80% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

// Text Glow Animation - Used in Super Market Sign

@keyframes text-glow {
  0%,
  60%,
  100% {
    text-shadow: none;
  }
  30% {
    text-shadow: 0 0 10px $white;
  }
}

// Box Glow Animation

@keyframes box-glow {
  0%,
  60%,
  100% {
    box-shadow: none;
  }
  30% {
    box-shadow: 0 0 50px red;
  }
}

//* @group ANCHOR SLIDE IN ANIMATION *//

// USE: Slide in from any direction or even diagonally.
// AUTHOR: NP

// INSTRUCTIONS:
// 1. Add the animation.
// 2. Add one or both css variables in order to change the initial positioning of the element.
// 3. Use the css variables according to the following rules:
// TOP:     --translateY with negative number.
// BOTTOM:  --translateY with positive number.
// LEFT:    --translateX with negative number.
// RIGHT:   --translateX with positive number.

// EXAMPLE
// #selector {
//   animation: slide-in 1s linear 0.4s both;
//   --translateX: -200px;
//   --translateY: -100px;
// }

@keyframes slide-in {
  0% {
    transform: translate(var(--translateX, 0), var(--translateY, 0));
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

// Blink Animation - Used in Window shines

@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.94);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation shake-tr
 * ----------------------------------------
 */
@keyframes shake-tr {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: top center;
  }
  10% {
    transform: rotate(0.05deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-0.05deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(0.05deg);
  }
  80% {
    transform: rotate(-0.05deg);
  }
  90% {
    transform: rotate(0.05deg);
  }
}

/**
 * ----------------------------------------
 * animation scale-in-hor-left
 * ----------------------------------------
 */
@keyframes scale-in-hor-left {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
