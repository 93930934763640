@import "global.scss";

body {
  margin: 0;
  font-family: "Ubuntu-Normal", sans-serif;
  font-size: $font-size-1;
}

.App {
  position: relative;
  width: 100vw;
  height: 100vh;

  .debug {
    position: absolute;
    z-index: +5;
    bottom: 0;
    padding: 2px;
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
    button {
      padding: 1px;
      margin: 1px;
      border: 1px solid gray;
      font-size: small;
      &:hover {
        background: yellow;
      }
    }
  }

  .Timeline {
    @include align-absolutePosition(0, 0, auto, 0);
    width: 100vw;
    height: 15vh;
    z-index: 2; // So it renders above the scene, character, items etc.

    @media only screen and (max-width: $media-md) {
      height: rem-calc(67);
    }

    @media only screen and (max-width: $media-md) {
      position: fixed;
      z-index: 2;
    }

    > div {
      @include align-absolutePosition(15%, 0, auto, 0);
      width: 93%;

      @media only screen and (min-width: $media-sm) {
        width: 80%;
      }

      @media only screen and (max-width: $media-md) {
        height: rem-calc(56);
        top: rem-calc(10);
      }
    }
  }

  // scene header background
  .scene:not(.Intro):not(.MapTransition):after {
    @media only screen and (max-width: $media-md) {
      content: "";
      position: fixed;
      z-index: 1;
      height: rem-calc(88);
      left: 0;
      bottom: 0;
      width: 100%;
    }

    @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
      height: rem-calc(68);
    }
  }

  // CharacterSelection - scene
  .scene.CharacterSelection:after {
    @media only screen and (max-width: $media-md) {
      background: $grey-3;
    }
  }

  // CharacterSetUp - scene
  .scene.CharacterSetUp:after {
    @media only screen and (max-width: $media-md) {
      background: $grey-3;
    }
  }

  // CharacterSetUp - scene
  .scene.Breakfast:after {
    @media only screen and (max-width: $media-md) {
      background: $breakfast-2;
    }
  }

  // transport - scene
  .scene.Transportation:after {
    @media only screen and (max-width: $media-md) {
      background: $transport-2;
    }
  }

  // Lunch - scene
  .scene.Lunch:after {
    @media only screen and (max-width: $media-md) {
      background: $lunch-2;
    }
  }

  // Supermarket - scene
  .scene.Supermarket:after {
    @media only screen and (max-width: $media-md) {
      background: $supermarket-2;
    }
  }

  // dinner-selection - scene
  .scene.DinnerSelection:after {
    @media only screen and (max-width: $media-md) {
      background: $restaurant-2;
    }
  }

  // Dinner Cooking - scene
  .scene.DinnerCooking:after {
    @media only screen and (max-width: $media-md) {
      background: $dinner-cooking;
    }
  }

  // Dinner Restaurant - scene
  .scene.DinnerDelivery:after {
    @media only screen and (max-width: $media-md) {
      background: $dinner-delivery;
    }
  }

  // Dinner Restaurant - scene
  .scene.DinnerDelivery:after {
    @media only screen and (max-width: $media-md) {
      background: $dinner-delivery;
    }
  }

  // Breaktime - scene
  .scene.Breaktime:after {
    @media only screen and (max-width: $media-md) {
      background: $breaktime;
    }
  }

  // Snacking - scene
  .scene.Snacking:after {
    @media only screen and (max-width: $media-md) {
      background: $Snacking;
    }
  }

  // World - scene
  .scene.World:after {
    @media only screen and (max-width: $media-md) {
      display: none;
    }
  }

  .scene {
    position: relative;
    width: 100vw;

    @media only screen and (min-width: $media-md) {
      height: 100vh;
    }

    @media only screen and (max-width: $media-md) {
      padding-bottom: rem-calc(60);
      min-height: 100%;
      overflow: hidden;
    }

    .item {
      position: absolute;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
    }

    // Item Buttons

    button.item {
      @extend %in-game-button;

      &:before {
        display: block;
        content: " ";
        margin: 0 auto;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:hover {
        @media only screen and (max-width: $media-md) {
          filter: none;
        }

        @media only screen and (min-width: $media-md) {
          filter: drop-shadow(3px 3px 0px $black);
        }
      }

      &:disabled {
        cursor: default;

        &:hover {
          filter: none;
        }

        label {
          display: none;
        }
      }
      transition: transform 0.5s ease-in-out;

      label {
        cursor: pointer;
      }
    }

    // Footer
    .Footer {
      @include align-absolutePosition(0, 0, 0, auto);
      margin: 0;
      width: 8vw;
      height: 50vh;
      z-index: 5;

      @media only screen and (max-width: $media-md) {
        top: auto;
        bottom: rem-calc(16);
        right: rem-calc(16);
        position: fixed;
        height: rem-calc(72);
        width: rem-calc(54);
      }

      @media only screen and (max-width: $media-sm) {
        right: 0;
      }

      @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
        bottom: rem-calc(4);
        right: 0;
      }

      > div {
        @include align-absolutePosition(5%, 30%, auto, auto);

        @media only screen and (max-width: $media-md) {
          ul {
            @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
              transform: scale(0.6);
              position: relative;
              bottom: rem-calc(-90);
            }
          }
        }

        @media only screen and (max-width: $media-md) {
          padding-bottom: rem-calc(70);
          bottom: 0;
          top: auto;
        }
      }

      // Menu open close
      .icon-burger-menu {
        @media only screen and (max-width: $media-md) {
          cursor: pointer;
          background-image: url("/elements/buttons/icon-button-menu.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          width: rem-calc(55);
          height: rem-calc(55);
          content: "";
          @include align-absolutePosition(auto, 0, 0, 0);
          margin-left: rem-calc(-16);
          font-size: 0;
        }

        @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
          transform: scale(0.6);
        }

        // Hide open icon div for large screens
        @media only screen and (min-width: $media-md) {
          display: none;
        }
      }

      // Menu is closed
      #nav:not(:target) {
        @media only screen and (max-width: $media-md) {
          height: 0;
          overflow: hidden;
          visibility: hidden;
          ul li:not(.goToNextScene) {
            display: none;
          }
        }

        @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
          height: auto;
        }
      }

      // Menu is open
      #nav:target {
        @media only screen and (max-width: $media-md) {
          display: flex;
          height: rem-calc(532);
          //opacity: 1;
          align-items: flex-end;
          visibility: visible;
        }

        // Burger menu - background overlay
        .burger-menu-background-overlay {
          @media only screen and (max-width: $media-md) {
            width: 100%;
            height: calc(100% - rem-calc(88));
            position: fixed;
            z-index: -1;
            background-color: rgba($black, 0.7);
            top: 0;
            left: 0;
            font-size: 0;
          }

          @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
            height: calc(100% - rem-calc(66));
          }
        }
      }

      // Burger menu - background overlay
      .burger-menu-background-overlay {
        @media only screen and (min-width: $media-md) {
          display: none;
        }
      }

      // Menu Close
      .menu-icon-close {
        @media only screen and (max-width: $media-md) {
          width: rem-calc(57);
          height: rem-calc(57);
          background-image: url("/elements/buttons/icon-button-menu-close.svg");
          background-repeat: no-repeat;
          position: absolute;
          bottom: 0;
          margin-left: rem-calc(-2);
          font-size: 0;
        }

        @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
          transform: scale(0.6);
        }

        // Hide close icon div for large screens
        @media only screen and (min-width: $media-md) {
          display: none;
        }
      }
    }

    // Hides menu only for intro - scene
    &.Intro .Footer {
      display: none;
    }

    .Battery {
      @include align-absolutePosition(0, 0, 0, auto);
      z-index: +2;
      width: 8vw;
      height: 100vh;

      @media only screen and (max-width: $media-md) {
        bottom: rem-calc(-370);
        top: auto;
        position: fixed;
        z-index: 2;

        left: rem-calc(340);
        right: auto;
        width: rem-calc(120);
        height: rem-calc(820);
        transform: rotate(90deg);
      }

      > div {
        @include align-absolutePosition(auto, 18%, 3%, auto);
        margin: 0;

        width: rem-calc(85);
        height: rem-calc(137);

        @media only screen and (min-width: $media-md) {
          width: 12vh;
          height: 20vh;
        }

        @media only screen and (max-width: $media-md) {
          right: 0;
          bottom: 2%;
        }

        @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
          width: rem-calc(68);
          height: rem-calc(105);
          right: 2%;
        }

        svg {
          width: 100%;
          height: 100%;

          // battery only for desktop
          #battery {
            @media only screen and (max-width: $media-md) {
              display: none;
            }

            @media only screen and (min-width: $media-md) {
              display: block;
            }
          }

          // battery only for mobile
          #battery-mobile {
            @media only screen and (max-width: $media-md) {
              display: block;
            }

            @media only screen and (min-width: $media-md) {
              display: none;
            }
          }
        }
      }
    }
  }
}

// Battery - lightning - rotated icon - different position for every scene
.Battery:before {
}

// burger-menu-background-overlay - Adds a bit more opacity to the background when modals open
.ReactModal__Body--open .App .scene .Footer #nav:target .burger-menu-background-overlay {
  background: none;
}

.ReactModal__Overlay {
  z-index: +100; // So it rendered above everything else in the App.
  // https://github.com/reactjs/react-modal/blob/master/docs/styles/index.md is producing the default background colour.
  // We used the !important rule to override the colour value, in order to avoid altering the index.md file.
  background-color: rgba(0, 0, 0, 0.85) !important;

  @media only screen and (max-width: $media-md) {
    overflow-x: scroll;
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
}

.ReactModal__Content {
  @include align-absolutePosition(0px, 0px, 0px, 0px);
  width: 80%;
  height: fit-content;
  padding: 1vh;

  @media only screen and (max-width: $media-md) {
    margin-top: rem-calc(60);
    padding-bottom: rem-calc(60);

    .Badges .items {
      height: fit-content;
      padding: rem-calc(32);
    }
  }

  @media only screen and (min-width: $media-md) {
    width: 75vw;
    height: 85vh;
  }

  border-radius: 1em;

  h2 {
    color: $white;
    margin-bottom: 2vh;

    @media only screen and (max-width: $media-md) {
      margin-top: rem-calc(44);
    }
  }

  .close {
    @include align-absolutePosition(-2.5vh, -2.5vh, auto, auto);
    width: 8.4vh;
    height: 8.4vh;

    @media only screen and (max-width: $media-md) {
      width: rem-calc(55);
      height: rem-calc(55);
    }

    background-image: url("elements/buttons/button-close-white.svg");
    background-position: center center;
    background-size: 100% 100%;
    @extend %no-text;

    &:active {
      background-image: url("elements/buttons/button-close-black.svg");
      transform: scale(1.2);
      transform-origin: center center;
    }
  }

  // Battery modal
  &.Battery {
    @include align-absolutePosition(auto, 0, rem-calc(30), 0);

    @media only screen and (min-width: $media-md) {
      width: 25%;
      @include align-absolutePosition(auto, 15vh, 5vh, auto);
    }

    height: auto;
    padding: 2vh;
    text-align: center;
    background: $white;
    color: $black;

    h2 {
      color: $black;
      font-size: $font-size--1;
      margin-top: rem-calc(16);
    }

    .close {
      filter: invert(80%);
      width: 6vh;
      height: 6vh;
    }
  }
}

// has-more - left & right arrows - general rules for shelves and inventory
//---------------------------------------------------------------------------------------

.has-more-before:before,
.has-more-after:after {
  @media only screen and (max-width: $media-md) {
    min-width: rem-calc(40);
    height: rem-calc(43);
    margin-top: rem-calc(27);
    z-index: 5;
    font-size: 0;
    display: block;
    position: fixed;
    position: sticky;
    background-color: black;
    color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: rem-calc(20);
  }
}

.has-more-before:before {
  @media only screen and (max-width: $media-md) {
    content: "\2190";
    left: 0;
    pointer-events: none;
    border-top-right-radius: rem-calc(112);
    border-bottom-right-radius: rem-calc(112);
    background-image: url(/elements/buttons/has-more-after-icon.svg);
  }
}
.has-more-after:after {
  @media only screen and (max-width: $media-md) {
    content: "\2192";
    left: auto;
    right: 0;
    pointer-events: none;
    border-top-left-radius: rem-calc(112);
    border-bottom-left-radius: rem-calc(112);
    background-image: url(/elements/buttons/has-more-before-icon.svg);
  }
}

// inventory
.inventory {
  .has-more-before:before,
  .has-more-after:after {
    margin-top: rem-calc(2);
  }
  .has-more-before:before {
    left: rem-calc(-12);
  }

  .has-more-after:after {
    right: rem-calc(-12);
  }
}
