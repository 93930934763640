@import "global.scss";
@import "common/Animations.scss";

.Battery {
  rect {
    transition: fill 0.5s linear;
  }
  @for $i from 1 through 8 {
    &.slot-#{$i}-orange #slot-#{$i} {
      fill: $battery-orange;
    }
    &.slot-#{$i}-red #slot-#{$i} {
      fill: $battery-red;
    }
    &.slot-#{$i}-green #slot-#{$i} {
      fill: $battery-green;
    }
  }
  #energy-overflow,
  #bounce {
    opacity: 0;
  }

  // Energy Overflow Animation

  &.energy-overflow #battery {
    animation: jello-horizontal 0.5s both; // Battery Animation
    transform-origin: 50% 50%;
  }

  &.energy-overflow #energy-overflow {
    animation: flicker 0.6s linear both; // Explosion
  }

  //  Charging / Discharging Animation

  &.slots-changed #battery {
    animation: wobble 0.4s steps(24, end) both; // Battery Animation
  }

  &.slots-changed #bounce {
    animation: flicker 0.2s linear both; // Sparkles Animation
  }
}
