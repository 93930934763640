@import "global.scss";
@import "common/items.scss";
@import "common/Animations.scss";

.World {
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3%;

  h1 {
    font-size: 2vh;
    display: flex;
    align-items: center;
    gap: 3%;
    @include get-icon(before, $common-icons, logo, $black, 10vh, 1, false);
    &:before {
      display: block;
      margin-bottom: 1vh;
    }
    text-transform: uppercase;
    span {
      font-size: 0;
      &:before {
        @include background-image("../elements", "2050.svg", no-repeat, center center, 100%, 100%, 11.5vh, 5.5vh);
        margin-top: 1vh;
      }
    }
  }

  button.restart {
    @extend %white-button;
    margin: 0 auto;
    display: block;
  }

  main {
    width: 100%;
    @media only screen and (min-width: $media-md) {
      width: 50%;
      height: 90%;
    }
    overflow-y: auto;

    @media only screen and (max-width: $media-md) {
      padding-bottom: 0;
    }
  }

  .world-illustration {
    @media only screen and (max-width: $media-md) {
      width: 80%;
      margin: auto;
      margin-top: rem-calc(40);
    }

    @media only screen and (min-width: $media-md) {
      position: fixed;
      width: 45%;
      height: 90%;
      top: 0;
      right: 0;
    }

    svg {
      width: 100%;
      @media only screen and (max-width: $media-sm) {
        height: auto;
      }
    }
  }

  // Healthy New World "Good" Scenario
  &.hnw {
    #cloud-small {
      animation: slide-in 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both, vibrate-1 7s linear infinite;
      --translateX: 40%;
    }

    #cloud-large {
      animation: slide-in 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s both, vibrate-1 7s 0.4s linear infinite;
      --translateX: 14%;
    }

    #sun {
      animation: scale-up-center 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      transform-origin: 50% 50% 0;
      animation-delay: 0.5s;
    }

    #bird-small {
      animation: scale-up-center 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both, vibrate-1 2.5s linear infinite;
      transform-origin: 50% 50% 0;
    }

    #bird-large {
      animation: scale-up-center 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both, vibrate-1 3.5s linear infinite;
      transform-origin: 50% 50% 0;
      animation-delay: 0.8s;
    }

    #sea-wave-small {
      animation: bounce-in-top 1.5s both, vibrate-1 4s 1.5s linear infinite;
      animation-delay: 1.6s;
    }
    #sea-wave {
      animation: bounce-in-top 1.5s both, vibrate-1 4s 1.5s linear infinite;
      animation-delay: 2s;
    }

    #ship {
      animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both,
        vibrate-1 4s 1s linear infinite;
      animation-delay: 2.8s;
    }
    #sailing-boat {
      animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both,
        vibrate-1 5s 1s linear infinite;
      animation-delay: 3.2s;
    }

    #bush {
      animation: swing-in-top-fwd 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s both;
    }
    #cypress {
      animation: swing-in-top-fwd 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s both;
    }

    #road {
      animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      transform-origin: 50% 50% 0;
      animation-delay: 1.4s;
    }

    #runners {
      animation: slide-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s both;
      --translateX: -25%;
    }

    #tree {
      animation: swing-in-top-fwd 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.8s both;
    }

    #sea-water {
      animation: scale-up-center 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both, vibrate-1 6s 1s linear infinite;
      transform-origin: 50% 50% 0;
      animation-delay: 1.8s;
    }

    #car-1 {
      animation: slide-in 0.7s ease-out 2s both;
      --translateX: 25%;
    }

    #car-2 {
      animation: slide-in 0.9s ease-out 2.1s both;
      --translateX: 25%;
    }

    #oven {
      animation: flicker 1s linear both;
      animation-delay: 2.8s;
    }

    #fridge-light {
    }

    #grocery-bag {
      animation: slide-in 0.3s ease-out 2s both;
      --translateY: -25%;
    }

    #kitchen-food {
      animation: slide-in 0.3s ease-out 2.2s both;
      --translateY: 25%;
    }

    #train {
      animation: slide-in 0.7s ease-out 4s both;
      --translateX: -25%;
    }

    #kitchen-light {
      animation: flicker 1.2s linear both;
      animation-delay: 2.2s;
    }
  }

  // Eat To Live "Bad" Scenario

  &.etl {
    #cloud-small {
      animation: slide-in 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      --translateX: -38%;
    }

    #cloud-large {
      animation: slide-in 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
      --translateX: 35%;
    }

    #moon {
      animation: scale-up-center 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      transform-origin: 50% 50% 0;
    }

    #sign-1 {
      animation: flicker 1s linear both;
      animation-delay: 0.8s;
    }
    #sign-2 {
      animation: flicker 0.5s linear both;
      animation-delay: 1s;
    }
    #sign-3 {
      animation: flicker 1.2s linear both;
      animation-delay: 1.8s;
    }
    #sign-4 {
      animation: flicker 0.85s linear both;
      animation-delay: 0.85s;
    }

    #sign-5 {
      animation: flicker 3.5s linear both infinite;
      animation-delay: 3s;
    }

    #sign-9 {
      animation: flicker 3.1s linear both infinite;
      animation-delay: 3.2s;
    }

    #blot-1 {
      animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation-delay: 1.6s;
    }

    #blot-2 {
      animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation-delay: 2s;
    }

    #blot-3 {
      animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation-delay: 1.8s;
    }

    #floating-1 {
      animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation-delay: 2.6s;
    }

    #floating-2 {
      animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation-delay: 2.8s;
    }

    #pump {
      animation: vibrate-1 0.6s linear infinite both;
      transform-origin: 50% 50%;
      animation-delay: 1s;
    }

    #drains-1 {
      animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation-delay: 1.6s;
    }
    #drains-2 {
      animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation-delay: 1.8s;
    }
    #drains-3 {
      animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation-delay: 1.4s;
    }
  }

  header {
    padding: 5% 5% 0 5%;
  }

  h2 {
    padding: 0 5%;
    margin: 0;
    width: 77%;
    height: auto;

    @media only screen and (min-width: $media-md) {
      width: 30vw;
    }
  }

  .world-description {
    padding: 3% 5%;
  }

  .badges {
    @extend %list-neutralizer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2%;
    margin: 0 auto;

    @media only screen and (max-width: $media-md) {
      align-items: flex-end;
      margin-bottom: rem-calc(12);
    }

    .badge {
      flex-basis: 15%;
      h5 {
        margin: 0;
        color: $text-color;
        text-decoration: none;
        text-align: center;
      }
      &:before {
        filter: none;
        opacity: 1;
        margin: 0 auto;
        display: block;
        content: " ";
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        width: 8vh;
        height: 8.5vh;
        @media only screen and (max-width: $media-md) {
          width: rem-calc(50);
          height: rem-calc(55);
        }
      }
      &.water-conservation-master:before,
      &.responsible-shopper:before {
        height: 9.5vh;
        @media only screen and (max-width: $media-md) {
          height: rem-calc(59);
        }
      }
    }
  }
}

.App .World.scene .Footer {
  position: relative;
  width: 100vw;
  height: 10vh;
  @media only screen and (max-width: $media-md) {
    position: relative;
    padding: 0 5%;
    .icon-burger-menu,
    .menu-icon-close,
    .burger-menu-background-overlay {
      display: none;
    }
  }

  > div {
    @media only screen and (min-width: $media-md) {
      left: 0;
      width: 15%;
      right: 0;
    }

    @media only screen and (max-width: $media-md) {
      height: auto !important;
      overflow: auto !important;
      visibility: visible !important;
      padding-bottom: 0;
      position: static;
    }
  }

  ul {
    height: auto;
    @media only screen and (min-width: $media-md) {
      gap: 5%;
      flex-direction: row;
    }

    @media only screen and (max-width: $media-md) {
      width: 89% !important;
      position: static !important;
      flex-direction: revert;
      justify-content: center !important;
    }

    li.restartGame {
      margin-right: rem-calc(16);
    }

    li.toggleSound {
      display: none;
    }
    li:not(.toggleSound):not(.fullScreen) {
      display: block !important;
    }
  }
}

.App .World.scene .Footer .icon-burger-menu {
  @media only screen and (max-width: $media-md) {
    left: auto;
    right: rem-calc(15);
  }
}
