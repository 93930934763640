@import "global.scss";
@import "common/items.scss";
@import "common/Animations.scss";

.Snacking {
  background: $snacking-1;

  @media only screen and (max-width: $media-md) {
    height: rem-calc(1535);
  }

  @media only screen and (max-width: $media-sm) {
    height: rem-calc(1355);
  }

  &:before {
    @include background-image("../elements/blobs", "blob-snacking.svg", no-repeat, center center, 75%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      background-size: rem-calc(612) rem-calc(394);
      height: rem-calc(394);
      top: rem-calc(94);
      bottom: auto;
    }
    @media only screen and (max-width: $media-sm) {
      margin-left: rem-calc(-194);
    }
  }

  .primary-elements {
    @include align-absolutePosition(0, 0, auto, 0);
    width: 100%;

    @media only screen and (min-width: $media-md) {
      @include align-absolutePosition(0, 0, 0, 0);
      width: 85vw;
      height: 85vh;
    }

    .bag {
      @include align-absolutePosition(rem-calc(90), auto, auto, 0);

      width: 100%;
      height: rem-calc(695);

      @media only screen and (min-width: $media-md) {
        @include background-image(
          "../elements/items",
          "snacking-bag.svg",
          no-repeat,
          center center,
          100%,
          100%,
          77vh,
          70.5vh
        );
        @include align-absolutePosition(12vh, 60vh, auto, 0);
        z-index: +1;
        background-size: cover;
      }

      @media only screen and (max-width: $media-md) {
        &:before {
          content: "";
          @include background-image(
            "../elements/items",
            "snacking-bag-mobile.svg",
            no-repeat,
            center center,
            100%,
            100%,
            rem-calc(468),
            rem-calc(504)
          );

          @include align-absolutePosition(0, auto, auto, 50%);
          margin-left: rem-calc(-315);
        }
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(45);
      }
    }

    .item {
      &.carrots-tomatoes {
        @include align-absolutePosition(rem-calc(1170), auto, auto, 50%);

        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-105);
        }

        @media only screen and (max-width: $media-sm) {
          top: rem-calc(1040);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(auto, auto, 9vh, 55vh);
        }

        animation: slide-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
        --translateX: -20%;
        --translateY: -80%;

        label {
          top: rem-calc(118);
          width: rem-calc(260);

          @media only screen and (max-width: $media-md) {
            left: 50%;
            right: auto;
            margin-left: rem-calc(-146);
          }

          @media only screen and (min-width: $media-md) {
            top: 19vh;
            width: min-content;
          }
        }
      }

      &.fresh-fruit {
        @include align-absolutePosition(rem-calc(908), auto, auto, 50%);

        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-68);
        }

        @media only screen and (max-width: $media-sm) {
          top: rem-calc(818);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(auto, auto, 27vh, 87vh);
        }

        animation: slide-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        --translateX: -140%;
        --translateY: -51%;

        label {
          top: rem-calc(68);
          width: rem-calc(198);

          @media only screen and (max-width: $media-md) {
            left: 50%;
            right: auto;
            margin-left: rem-calc(-188);
          }

          @media only screen and (min-width: $media-md) {
            top: 27vh;
            width: min-content;
          }
        }
      }
      &.fresh-fruit:hover label {
        filter: drop-shadow(3px 3px 0px $black);
      }

      &.fresh-fruit:hover:before {
        filter: drop-shadow(3px 3px 0px $black);
      }

      &.chocolate-bar {
        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-88);
        }

        @include align-absolutePosition(rem-calc(298), auto, auto, 50%);

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(auto, auto, 33vh, 34vh);
        }

        animation: slide-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s both;
        --translateX: 100%;
        --translateY: -44%;

        &:before {
          @media only screen and (max-width: $media-md) {
            transform: rotate(-9deg);
          }
        }

        label {
          top: rem-calc(118);

          @media only screen and (max-width: $media-md) {
            width: rem-calc(218);
            left: 50%;
            right: auto;
            margin-left: rem-calc(-104);
          }

          @media only screen and (min-width: $media-md) {
            top: 16vh;
          }
        }
      }

      &.chips {
        @include align-absolutePosition(rem-calc(515), auto, auto, 50%);

        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-15);
        }

        @media only screen and (max-width: $media-md) {
          top: rem-calc(480);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(auto, auto, 45vh, 60vh);
        }

        animation: slide-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
        --translateX: -50%;
        --translateY: 30%;

        label {
          top: rem-calc(135);
          width: rem-calc(154);

          @media only screen and (max-width: $media-md) {
            right: auto;
            left: 50%;
            margin-left: rem-calc(-222);
          }

          @media only screen and (min-width: $media-md) {
            top: 16vh;
            width: min-content;
          }
        }
      }

      &.chips:before,
      &.chips .item-text h3:before {
        @media only screen and (max-width: $media-md) {
          @include background-image("../elements/items", "chips-mobile.svg", no-repeat, center center, 100%, 100%);
        }
      }

      &.juice-drink {
        @include align-absolutePosition(auto, auto, 60vh, 39vh);
        animation: slide-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s both;
        --translateX: 190%;
        --translateY: 50%;

        @media only screen and (max-width: $media-md) {
          margin-left: rem-calc(-135);
          left: 50%;
          top: rem-calc(742);

          &:before {
            transform: rotate(-35deg);
          }
        }

        @media only screen and (max-width: $media-sm) {
          top: rem-calc(672);
        }

        label {
          top: rem-calc(75);

          @media only screen and (max-width: $media-md) {
            left: 50%;
            right: auto;
            width: rem-calc(198);
            margin-left: rem-calc(18);
          }

          @media only screen and (min-width: $media-md) {
            top: 16vh;
          }
        }
      }
      &:not(.selected):disabled {
        filter: grayscale(100%) contrast(50%) brightness(140%);
      }
    }

    button.bottle {
      @include align-absolutePosition(rem-calc(100), auto, auto, 50%);
      width: rem-calc(155);
      height: rem-calc(130);

      @media only screen and (max-width: $media-md) {
        transform: rotate(95deg);
        margin-left: rem-calc(9);
      }

      @media only screen and (min-width: $media-md) {
        @include align-absolutePosition(auto, 48.5vh, 51.5vh, auto);
        width: 18.5vh;
        height: 15.5vh;
      }

      svg {
        height: 100%;
        width: 100%;

        // Water level & Bottle Cap
        #full-water,
        #closed {
          opacity: 0;
        }
        #open {
          #tap {
            animation: rotate 1.5s infinite 1.6s;
          }
        }
      }

      &:disabled {
        svg {
          // Water level & Bottle Cap
          #full-water,
          #closed {
            opacity: 1;
          }
          #open {
            opacity: 0;
            #tap {
              animation: none;
            }
          }
        }
        &:hover svg {
          filter: none;
        }
      }
    }
  }
}
