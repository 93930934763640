@import "global.scss";
@import "common/Animations.scss";

.Footer {
  ul {
    @extend %list-neutralizer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 50vh;
    justify-content: space-between;

    @media only screen and (max-width: $media-md) {
      justify-content: flex-end;
      margin-bottom: rem-calc(12);
      width: rem-calc(55);
    }

    @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
      height: 100vh;
    }

    li {
      @media only screen and (max-width: $media-md) {
        margin-top: rem-calc(20);
      }

      @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
        margin-top: rem-calc(10);
      }

      button {
        @extend %no-text;
        content: " ";
        width: 6vh;
        height: 6vh;

        @media only screen and (max-width: $media-md) {
          width: rem-calc(55);
          height: rem-calc(55);
        }

        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      &.notify {
        animation: shake 1s linear infinite;
      }

      &.selectionsModal button {
        background-image: url("../elements/buttons/icon-button-info.svg");
      }

      &.badgesModal button {
        background-image: url("../elements/buttons/icon-button-badges.svg");
      }

      &.toggleSound button {
        background-image: url("../elements/buttons/icon-button-sound-on.svg");
      }

      &.toggleSound.off button {
        background-image: url("../elements/buttons/icon-button-sound-off.svg");
      }

      &.fullScreen {
        @media only screen and (max-width: $media-sm) {
          display: none;
        }
      }

      &.fullScreen button {
        background-image: url("../elements/buttons/icon-button-full-screen.svg");
      }

      @media all and (display-mode: fullscreen) {
        &.fullScreen button {
          background-image: url("../elements/buttons/icon-button-exit-full-screen.svg");
        }
      }

      &.restartScene button {
        background-image: url("../elements/buttons/icon-button-restart-scene.svg");
      }

      &.restartGame button {
        background-image: url("../elements/buttons/icon-button-restart-game.svg");
      }

      &.goToNextScene {
        .-scene-completed & {
          @media only screen and (max-width: $media-md) {
            visibility: visible;
            position: fixed;
            top: 0;
            right: rem-calc(28);
            margin-top: rem-calc(8);
          }

          @media only screen and (max-width: $media-sm) {
            right: rem-calc(12);
          }

          @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
            top: rem-calc(-132);
            right: 0;
          }
        }

        button {
          background-image: url("../elements/buttons/icon-button-next.svg");

          @media only screen and (max-width: $media-md) {
            background-image: url("../elements/buttons/icon-button-next-mobile.svg");
          }

          animation: brightness 2s ease-in-out infinite;
          animation-delay: 1.5s;
          margin: 0;
          width: 9vh;

          @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
            width: rem-calc(50);
            height: rem-calc(50);
          }
        }
      }

      &.shareResult button {
        background-image: url("../elements/buttons/icon-button-share.svg");
      }
    }
  }
}

// goToNextScene - mobile
.-scene-completed .Footer #nav:target ul li.goToNextScene {
  @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
    top: rem-calc(-140);
  }

  @media only screen and (max-height: $media-xs) and (max-width: $media-md) {
    top: rem-calc(-110);
  }
}

.-scene-completed .Footer #nav:not(:target) ul li.goToNextScene {
  @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
    top: rem-calc(-110);
  }

  @media only screen and (max-height: $media-xs) and (max-width: $media-md) {
    top: rem-calc(-80);
  }
}
