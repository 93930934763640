@import "global.scss";

.CharacterSelection {
  background: $grey-1;

  @media only screen and (max-width: $media-md) {
    padding-bottom: rem-calc(92) !important;
  }

  @media only screen and (max-width: $media-sm) and (orientation: landscape) {
    padding-bottom: rem-calc(60) !important;
  }

  aside {
    z-index: 1;
    position: relative;

    @media only screen and (max-width: $media-md) {
      padding: rem-calc(32) rem-calc(32) 0 rem-calc(32);
    }

    @media only screen and (min-width: $media-md) {
      @include align-absolutePosition(0, auto, 0, 0);
      height: 60vh;
      width: 40vw;
    }

    .scene-text {
      @media only screen and (max-width: $media-md) {
        margin: auto;
      }

      @media only screen and (max-width: $media-sm) {
        h2 {
          font-size: rem-calc(24);
        }

        p {
          font-size: rem-calc(14);
        }
      }

      @media only screen and (min-width: $media-md) {
        @include align-absolutePosition(15%, 15%, auto, auto);
        width: 60%;
      }
    }
  }

  main {
    width: 100%;
    z-index: 0;

    @media only screen and (max-width: $media-md) {
      margin-top: rem-calc(120);
    }

    @media only screen and (max-width: $media-sm) {
      margin-top: rem-calc(55);
    }

    @media only screen and (max-width: $media-xs) {
      margin-top: rem-calc(8);
    }

    @media only screen and (min-width: $media-md) {
      @include align-absolutePosition(0, 0, 0, auto);
      height: 70vh;
      width: 55vw;
    }

    .characters {
      display: flex;
      gap: 30%;
      align-items: flex-end;

      @media only screen and (max-width: $media-md) {
        justify-content: center;
      }

      @media only screen and (max-width: $media-sm) {
        gap: 26%;
      }

      @media only screen and (max-width: $media-xs) {
        gap: rem-calc(40);
      }

      > div {
        text-align: center;
      }

      // adult
      .Character.adult {
        @media only screen and (max-width: $media-md) {
          width: rem-calc(130);
          height: rem-calc(410);
        }
        @media only screen and (max-width: $media-sm) {
          width: rem-calc(82);
          height: rem-calc(259);
          margin: auto;
        }

        @media only screen and (max-width: $media-xs) {
          transform: scale(0.7);
        }
      }

      div.adult:before {
        @include background-image(
          "../elements/blobs",
          "blob-adult.svg",
          no-repeat,
          center center,
          100%,
          100%,
          40vh,
          90vh
        );
        @include align-absolutePosition(0, 0, 0, -52%);

        @media only screen and (max-width: $media-md) {
          width: rem-calc(270);
          height: rem-calc(480);
        }

        @media only screen and (max-width: $media-sm) {
          width: rem-calc(163);
          height: rem-calc(326);
        }
      }

      // child
      .Character.child {
        @media only screen and (max-width: $media-md) {
          width: rem-calc(97);
          height: rem-calc(290);
          margin: auto;
          z-index: 0;
        }

        @media only screen and (max-width: $media-sm) {
          width: rem-calc(76);
          height: rem-calc(230);
          margin: auto;
        }

        @media only screen and (max-width: $media-xs) {
          transform: scale(0.7);
        }
      }

      div.child:before {
        @include background-image(
          "../elements/blobs",
          "blob-child.svg",
          no-repeat,
          center center,
          100%,
          100%,
          40vh,
          72vh
        );
        @include align-absolutePosition(0, 0, -10%, -65%);

        @media only screen and (max-width: $media-md) {
          width: rem-calc(210);
          height: rem-calc(360);
        }

        @media only screen and (max-width: $media-sm) {
          width: rem-calc(161);
          height: rem-calc(286);
        }
      }
    }

    button {
      @extend %white-button;
      margin-top: rem-calc(-32);

      @media only screen and (min-width: $media-xs) {
        margin-top: 2vh;
      }
      position: relative;
    }
  }
}
