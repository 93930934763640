@import "global.scss";

// prettier-ignore
$background-svgs: (
  // Breakfast
    no-drink:                   ( width: 5.5vh, height: 5.5vh),
    tap-water:                  ( width: 5vh,   height: 8vh),
    milk:                       ( width: 6.5vh, height: 11vh),
    freshly-made-juice:         ( width: 4vh,   height: 8vh),
    coffee-or-tea:              ( width: 10vh,  height: 7vh),
    plant-based-drink:          ( width: 6.5vh, height: 11vh),
    whole-grain-cereals:        ( width: 9vh,   height: 8vh),
    choco-balls:                ( width: 8.5vh, height: 9vh),
    white-bread-or-toast:       ( width: 10vh,  height: 6.5vh),
    wholemeal-bread-or-toast:   ( width: 10vh,  height: 6.5vh),
    biscuits:                   ( width: 6vh,   height: 9.5vh),
    yogurt:                     ( width: 6.5vh, height: 8vh),
    no-topping:                 ( width: 5.5vh, height: 5.5vh),
    honey:                      ( width: 6vh,   height: 4.5vh),
    jam:                        ( width: 5vh,   height: 6.5vh),
    fruit:                      ( width: 12.5vh,  height: 6vh),
    butter:                     ( width: 9vh,   height: 4.5vh),
    chocolate-cream:            ( width: 6vh,   height: 8.5vh),
    ham-or-bacon:               ( width: 11vh,  height: 5vh),
    cheese:                     ( width: 9vh,   height: 5vh),
    avocado-and-tomato:         ( width: 9.5vh, height: 6vh),

  // Transportation
    walk:                       ( width: 13vh,   height: 24vh),
    bicycle:                    ( width: 9vh,    height: 16vh),
    scooter-or-motorbike:       ( width: 10vh,   height: 17vh),
    car:                        ( width: 18.5vh, height: 16vh),
    public-transport:           ( width: 64vh,   height: 38.5vh),
    
  // Lunch  
    salad-with-egg:             ( width: 10.5vh, height: 10.5vh),
    beef-steak:                 ( width: 13vh,   height: 7.5vh),
    chicken:                    ( width: 13.5vh, height: 5.5vh),
    lentil-soup:                ( width: 10.5vh, height: 7vh),
    vegetable-burger:           ( width: 9.5vh, height: 8vh),
    white-pasta:                ( width: 13vh, height: 6vh),
    wholegrain-pasta:           ( width: 13vh, height: 6vh),
    white-rice:                 ( width: 10.5vh, height: 8.5vh),
    wholegrain-rice:            ( width: 10.5vh, height: 8.5vh),
    white-bread-bun:            ( width: 9.5vh, height: 5vh),
    no-salad:                   ( width: 5.5vh, height: 5.5vh),
    fresh-green-salad:          ( width: 10.5vh, height: 10.5vh),
    french-fries:               ( width: 11.5vh, height: 6.5vh),
    boiled-broccoli:            ( width: 12.5vh, height: 5vh),
    bottled-water:              ( width: 5.5vh, height: 13vh),
    soft-drink :                ( width: 4vh, height: 7.5vh),
    beer-or-wine:               ( width: 4vh, height: 13vh),

  // Breaktime
    watching-tv:                ( width: 43vh, height: 33vh),
    doing-sports:               ( width: 25.5vh, height: 22vh),
    playing-outside:            ( width: 38vh, height: 29.5vh), 
    charity-work:               ( width: 19vh, height: 18.5vh),

  // Snacking   
    carrots-tomatoes:           ( width: 30vh, height: 35.5vh),
    fresh-fruit:                ( width: 29vh, height: 26vh),
    chocolate-bar:              ( width: 20.8vh, height: 22vh),
    chips:                      ( width: 29vh, height: 26vh),
    juice-drink:                ( width: 12.5vh, height: 18vh),

  // Super Market  
      raw-fish:                 ( width: 8vh, height: 6.2vh),
      raw-beef:                 ( width: 11.2vh, height: 6vh),
      raw-chicken:              ( width: 9vh, height: 6vh),
      whole-salad:              ( width: 8.7vh, height: 4.2vh),
      packaged-salad:           ( width: 5vh, height: 7vh),
      seasonal-fruit:           ( width: 8vh, height: 6vh),
      exotic-fruit:             ( width: 13vh, height: 7.5vh),
      ham:                      ( width: 23.5vh, height: 7vh),
      tofu:                     ( width: 18vh, height: 7vh),
      hummus:                   ( width: 18vh, height: 5.5vh),
      precooked-pizza:          ( width: 25.5vh, height: 7.5vh),
      precooked-fries:          ( width: 24vh, height: 7.5vh),

  // Dinner Selection 
    food-delivery:              ( width: 26vh, height: 38vh),
    cooking-at-home:            ( width: 26vh, height: 38vh),

  // Dinner Delivery
     cheeseburger:               ( width: 14vh, height: 10.5vh),
     mushroom-risoto:            ( width: 11vh, height: 8vh),
     noodles-with-vegetables:    ( width: 11vh, height: 11vh),
     pizza-and-soft-drink:       ( width: 12vh, height: 10vh),

  // DinnerCooking
     precooked-meal:             ( width: 7vh, height: 6.2vh),
     frozen-pizza:               ( width: 14vh, height: 6vh),
     rice-and-salad:             ( width: 13.8vh, height: 6.3vh),
     ham-sandwich-and-soft-drink:( width: 9vh, height: 6vh),
     wholegrain-pasta-with-sauce:( width: 14vh, height: 6vh)   
    
);

@each $name, $code in $background-svgs {
  button.item.#{$name}:before,
  .item.#{$name} .item-text h3:before {
    width: map-get($code, width);
    height: map-get($code, height);
    background-image: url("../elements/items/#{$name}.svg");
  }
}

// Mobile
//--------------------------------------------------------

// drink
button.item.no-drink:before,
.item.no-drink .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(48);
    height: rem-calc(48);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(40) rem-calc(40) !important;
  }
}

// tap-water
button.item.tap-water:before,
.item.tap-water .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(44);
    height: rem-calc(71);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(26) rem-calc(44) !important;
  }
}

// milk
button.item.milk:before,
.item.milk .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(57);
    height: rem-calc(96);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(29) rem-calc(48) !important;
  }
}

// freshly-made-juice
button.item.freshly-made-juice:before,
.item.freshly-made-juice .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(35);
    height: rem-calc(71);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(24) rem-calc(48) !important;
  }
}

// coffee-or-tea
button.item.coffee-or-tea:before,
.item.coffee-or-tea .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(87);
    height: rem-calc(61);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(48) rem-calc(35) !important;
  }
}

// plant-based-drink
button.item.plant-based-drink:before,
.item.plant-based-drink .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(56);
    height: rem-calc(96);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(29) rem-calc(48) !important;
  }
}

// yogurt
button.item.yogurt:before,
.item.yogurt .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(50);
    height: rem-calc(61);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(36) rem-calc(44) !important;
  }
}

// whole-grain-cereals
button.item.whole-grain-cereals:before,
.item.whole-grain-cereals .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(69);
    height: rem-calc(61);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(44) rem-calc(40) !important;
  }
}

// choco-balls
button.item.choco-balls:before,
.item.choco-balls .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(65);
    height: rem-calc(69);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(44) rem-calc(43) !important;
  }
}

// white-bread-or-toast
button.item.white-bread-or-toast:before,
.item.white-bread-or-toast .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(76);
    height: rem-calc(50);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(50) rem-calc(32) !important;
  }
}

// wholemeal-bread-or-toast
button.item.wholemeal-bread-or-toast:before,
.item.wholemeal-bread-or-toast .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(76);
    height: rem-calc(50);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(50) rem-calc(32) !important;
  }
}

// biscuits
button.item.biscuits:before,
.item.biscuits .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(46);
    height: rem-calc(72);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(31) rem-calc(48) !important;
  }
}

// butter
button.item.butter:before,
.item.butter .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(66);
    height: rem-calc(33);
  }
}

// chocolate-cream
button.item.chocolate-cream:before,
.item.chocolate-cream .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(44);
    height: rem-calc(63);
  }
}

// ham-or-bacon
button.item.ham-or-bacon:before,
.item.ham-or-bacon .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(81);
    height: rem-calc(37);
  }
}

// cheese
button.item.cheese:before,
.item.cheese .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(66);
    height: rem-calc(37);
  }
}

// avocado-and-tomato
button.item.avocado-and-tomato:before,
.item.avocado-and-tomato .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(70);
    height: rem-calc(44);
  }
}

// walk
button.item.walk:before,
.item.walk .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(95);
    height: rem-calc(162);
  }
}

// bicycle
button.item.bicycle:before,
.item.bicycle .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(68);
    height: rem-calc(112);
  }
}

// public-transport
button.item.public-transport:before,
.item.public-transport .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(456);
    height: rem-calc(274);
  }
  @media only screen and (max-width: $media-sm) {
    width: rem-calc(402);
  }
}

// scooter-or-motorbike
button.item.scooter-or-motorbike:before,
.item.scooter-or-motorbike .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(75);
    height: rem-calc(128);
  }
}

// car
button.item.car:before,
.item.car .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(140);
    height: rem-calc(117);
  }
}

// salad-with-egg
button.item.salad-with-egg:before,
.item.salad-with-egg .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(98);
    height: rem-calc(98);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(45) rem-calc(45) !important;
  }
}

// beef-steak
button.item.beef-steak:before,
.item.beef-steak .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(122);
    height: rem-calc(70);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(54) rem-calc(30) !important;
  }
}

// chicken
button.item.chicken:before,
.item.chicken .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(127);
    height: rem-calc(52);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(53) rem-calc(32) !important;
    margin-bottom: -3px !important;
  }
}

// lentil-soup
button.item.lentil-soup:before,
.item.lentil-soup .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(98);
    height: rem-calc(66);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(44) rem-calc(29) !important;
  }
}

// vegetable-burger
button.item.vegetable-burger:before,
.item.vegetable-burger .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(89);
    height: rem-calc(75);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(40) rem-calc(33) !important;
  }
}

// white-pasta
button.item.white-pasta:before,
.item.white-pasta .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(122);
    height: rem-calc(56);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(54) rem-calc(29) !important;
  }
}

// wholegrain-pasta
button.item.wholegrain-pasta:before,
.item.wholegrain-pasta .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(122);
    height: rem-calc(56);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(54) rem-calc(29) !important;
  }
}

// white-rice
button.item.white-rice:before,
.item.white-rice .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(98);
    height: rem-calc(80);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(44) rem-calc(36) !important;
  }
}

// wholegrain-rice
button.item.wholegrain-rice:before,
.item.wholegrain-rice .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(98);
    height: rem-calc(80);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(44) rem-calc(36) !important;
  }
}

// white-bread-bun
button.item.white-bread-bun:before,
.item.white-bread-bun .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(89);
    height: rem-calc(47);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(48) rem-calc(25) !important;
  }
}

// no-salad
button.item.no-salad:before,
.item.no-salad .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(52);
    height: rem-calc(52);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(44) rem-calc(44) !important;
  }
}

// fresh-green-salad
button.item.fresh-green-salad:before,
.item.fresh-green-salad .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(98);
    height: rem-calc(98);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(44) rem-calc(44) !important;
  }
}

// french-fries
button.item.french-fries:before,
.item.french-fries .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(108);
    height: rem-calc(61);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(52) rem-calc(32) !important;
  }
}

// boiled-broccoli
button.item.boiled-broccoli:before,
.item.boiled-broccoli .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(117);
    height: rem-calc(47);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(50) rem-calc(24) !important;
  }
}

// bottled-water
button.item.bottled-water:before,
.item.bottled-water .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(52);
    height: rem-calc(122);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(21) rem-calc(48) !important;
  }
}

// soft-drink
button.item.soft-drink:before,
.item.soft-drink .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(37);
    height: rem-calc(70);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(23) rem-calc(44) !important;
  }
}

// beer-or-wine
button.item.beer-or-wine:before,
.item.beer-or-wine .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(37);
    height: rem-calc(122);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(19) rem-calc(52) !important;
  }
}

// DinnerSelection --- page
//--------------------------------------------------------------

// food-delivery
button.item.food-delivery:before,
.item.food-delivery .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(111);
    height: rem-calc(162);
  }
}

// cooking-at-home
button.item.cooking-at-home:before,
.item.cooking-at-home .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(111);
    height: rem-calc(162);
  }
}

// DinnerDelivery --- page
//--------------------------------------------------------------

// cheeseburger
button.item.cheeseburger:before,
.item.cheeseburger .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(120);
    height: rem-calc(90);
  }
}

// mushroom-risoto
button.item.mushroom-risoto:before,
.item.mushroom-risoto .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(105);
    height: rem-calc(77);
  }
}

// noodles-with-vegetables
button.item.noodles-with-vegetables:before,
.item.noodles-with-vegetables .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(105);
    height: rem-calc(106);
  }
}

// pizza-and-soft-drink
button.item.pizza-and-soft-drink:before,
.item.pizza-and-soft-drink .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(113);
    height: rem-calc(94);
  }
}

// dinner-cooking

// precooked-meal
button.item.precooked-meal:before,
.item.precooked-meal .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(51);
    height: rem-calc(42);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(40) rem-calc(36) !important;
  }
}

// frozen-pizza
button.item.frozen-pizza:before,
.item.frozen-pizza .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(115);
    height: rem-calc(59);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(40) rem-calc(40) !important;
  }
}

// rice-and-salad
button.item.rice-and-salad:before,
.item.rice-and-salad .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(117);
    height: rem-calc(53);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(55) rem-calc(24) !important;
  }
}

// ham-sandwich-and-soft-drink
button.item.ham-sandwich-and-soft-drink:before,
.item.ham-sandwich-and-soft-drink .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(72);
    height: rem-calc(48);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(50) rem-calc(32) !important;
  }
}

// wholegrain-pasta-with-sauce
button.item.wholegrain-pasta-with-sauce:before,
.item.wholegrain-pasta-with-sauce .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(119);
    height: rem-calc(51);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(50) rem-calc(24) !important;
  }
}

// Supermarket --- page
//--------------------------------------------------------------

// seasonal-fruit
button.item.seasonal-fruit:before,
.item.seasonal-fruit .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(65);
    height: rem-calc(49);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(48) rem-calc(37) !important;
  }
}

// ham
button.item.ham:before,
.item.ham .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(57);
    height: rem-calc(57);
    background-repeat: no-repeat !important;
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(43) rem-calc(41) !important;
  }
}

// exotic-fruit
button.item.exotic-fruit:before,
.item.exotic-fruit .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(106);
    height: rem-calc(61);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(48) rem-calc(37) !important;
  }
}

// tofu
button.item.tofu:before,
.item.tofu .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(57);
    height: rem-calc(57);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(34) rem-calc(48) !important;
  }
}

// precooked-pizza
button.item.precooked-pizza:before,
.item.precooked-pizza .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(97);
    height: rem-calc(61);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(44) rem-calc(44) !important;
  }
}

// hummus
button.item.hummus:before,
.item.hummus .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(82);
    height: rem-calc(45);
    background-repeat: no-repeat !important;
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(30) rem-calc(44) !important;
  }
}

// packaged-salad
button.item.packaged-salad:before,
.item.packaged-salad .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(41);
    height: rem-calc(57);
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(34) rem-calc(48) !important;
  }
}

// precooked-fries
button.item.precooked-fries:before,
.item.precooked-fries .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(92);
    height: rem-calc(61);
    background-repeat: no-repeat !important;
  }

  @media only screen and (max-width: $media-sm) {
    background-size: rem-calc(34) rem-calc(48) !important;
  }
}

// raw-fish
button.item.raw-fish:before,
.item.raw-fish .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(74);
    height: rem-calc(57);
  }

  @media only screen and (max-width: $media-sm) {
    background-image: url(../elements/items/raw-fish-single.svg) !important;
    background-size: rem-calc(48) rem-calc(22) !important;
  }
}

// raw-beef
button.item.raw-beef:before,
.item.raw-beef .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(103);
    height: rem-calc(55);
  }

  @media only screen and (max-width: $media-sm) {
    background-image: url(../elements/items/raw-beef-single.svg);
    background-size: rem-calc(35) rem-calc(35) !important;
  }
}

// raw-chicken
button.item.raw-chicken:before,
.item.raw-chicken .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(83);
    height: rem-calc(55);
  }

  @media only screen and (max-width: $media-sm) {
    background-image: url(../elements/items/raw-chicken-single.svg);
    background-size: rem-calc(44) rem-calc(30) !important;
  }
}

// whole-salad
button.item.whole-salad:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(80);
    height: rem-calc(39);
  }

  @media only screen and (max-width: $media-sm) {
    background-image: url(../elements/items/whole-salad-full.svg);
    background-size: rem-calc(42) rem-calc(56) !important;
  }
}

.item.whole-salad .item-text h3:before {
  background-image: url(../elements/items/whole-salad-full.svg);
}

.item.whole-salad .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    background-image: url(../elements/items/vegetable-for-modal.svg);
  }
}

// breaktime --- page
//--------------------------------------------------------------

// watching-tv
button.item.watching-tv:before,
.item.watching-tv .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(249);
    height: rem-calc(192);
  }
}

// charity-work
button.item.charity-work:before,
.item.charity-work .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(178);
    height: rem-calc(169);
  }
}

// doing-sports
button.item.doing-sports:before,
.item.doing-sports .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(236);
    height: rem-calc(203);
  }
}

// playing-outside
button.item.playing-outside:before,
.item.playing-outside .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(300);
    height: rem-calc(233);
  }
}

// snacking --- page
//--------------------------------------------------------------

// chips
button.item.chips:before,
.item.chips .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(208);
    height: rem-calc(220);
  }
}

// juice-drink
button.item.juice-drink:before,
.item.juice-drink .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(75);
    height: rem-calc(108);
  }
}

// fresh-fruit
button.item.fresh-fruit:before,
.item.fresh-fruit .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(210);
    height: rem-calc(227);
  }
}

// chocolate-bar
button.item.chocolate-bar:before,
.item.chocolate-bar .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(125);
    height: rem-calc(133);
  }
}

// carrots-tomatoes
button.item.carrots-tomatoes:before,
.item.carrots-tomatoes .item-text h3:before {
  @media only screen and (max-width: $media-md) {
    width: rem-calc(212);
    height: rem-calc(218);
  }
}

// For all items - smaller devices 768px and down
//-------------------------------------------------------------
.table button.item:before,
.table .item .item-text h3:before,
.inventory button.item:before,
.inventory .item .item-text h3:before,
.shelves button.item:before,
.fridge button.item:before {
  @media only screen and (max-width: $media-sm) {
    width: rem-calc(48) !important;
    height: rem-calc(48) !important;
    background-position: bottom center !important;
  }
}
