@import "global.scss";

.Selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5vh 0;

  @media only screen and (max-width: $media-md) {
    margin: rem-calc(8) 0;
  }

  .icon {
    width: rem-calc(45);
    height: rem-calc(45);

    @media only screen and (min-width: $media-sm) {
      width: rem-calc(66);
      height: rem-calc(66);
    }

    @media only screen and (min-width: $media-md) {
      width: 9vh;
      height: 9vh;
    }

    border-radius: 100%;
    display: block;
    color: rgba(255, 255, 255, 0);
    overflow: hidden;
    user-select: none;
  }

  &.outfit {
    .icon {
      border-radius: 0;
      width: rem-calc(148);
      height: rem-calc(180);

      @media only screen and (min-width: $media-sm) {
        width: rem-calc(121);
        height: rem-calc(243);
      }

      @media only screen and (min-width: $media-md) {
        width: 15vh;
        height: 30vh;
      }
    }
  }

  button {
    background: $grey-2;
    width: 6vh;
    height: 6vh;

    @media only screen and (max-width: $media-md) {
      width: rem-calc(38);
      height: rem-calc(38);
    }

    @media only screen and (max-width: $media-sm) {
      width: rem-calc(28);
      height: rem-calc(28);
    }

    border-radius: 100%;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    border: 0;

    &:before {
      opacity: 1;
    }

    &.previous {
      @include get-icon(before, $common-icons, previous, $black, 2.5vh, 1, true);

      @media only screen and (max-width: $media-md) {
        @include get-icon(before, $common-icons, previous, $black, 1.25rem, 1, true);
      }

      @media only screen and (max-width: $media-sm) {
        @include get-icon(before, $common-icons, previous, $black, 1rem, 1, true);
      }
    }

    &.next {
      @include get-icon(before, $common-icons, next, $black, 2.5vh, 1, true);

      @media only screen and (max-width: $media-md) {
        @include get-icon(before, $common-icons, next, $black, 1.25rem, 1, true);
      }

      @media only screen and (max-width: $media-md) {
        @include get-icon(before, $common-icons, next, $black, 1rem, 1, true);
      }
    }
  }

  &.outfit button {
    @media only screen and (max-width: $media-sm) {
      width: rem-calc(48);
    }
  }

  /* skin colours (shared between adult and child) */
  .icon[class*="skinTone-1"] {
    background: #8b0b99 url("skinTone-1.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon[class*="skinTone-2"] {
    background: #065360 url("skinTone-2.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon[class*="skinTone-3"] {
    background: #aed603 url("skinTone-3.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon[class*="skinTone-4"] {
    background: #fcd227 url("skinTone-4.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon[class*="skinTone-5"] {
    background: #00b1ef url("skinTone-5.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-outfit-1 {
    background: url("adult-outfit-1.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.adult-outfit-2 {
    background: url("adult-outfit-2.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.adult-outfit-3 {
    background: url("adult-outfit-3.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.adult-outfit-4 {
    background: url("adult-outfit-4.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.adult-outfit-5 {
    background: url("adult-outfit-5.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.adult-hairStyle-black {
    background: #ffa81d url("adult-hairStyle-black.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-hairStyle-black-curly {
    background: #0d991a url("adult-hairStyle-black-curly.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-hairStyle-blonde {
    background: #1b0a99 url("adult-hairStyle-blonde.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-hairStyle-brown {
    background: #5c0ed8 url("adult-hairStyle-brown.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-hairStyle-grey {
    background: #e0247e url("adult-hairStyle-grey.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-accessory-glasses {
    background: #212121 url("adult-accessory-glasses.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-accessory-scarf {
    background: #472401 url("adult-accessory-scarf.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-accessory-watch {
    background: #424aea url("adult-accessory-watch.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-accessory-white-hat {
    background: #2a57f5 url("adult-accessory-white-hat.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.adult-accessory-yellow-hat {
    background: #017f79 url("adult-accessory-yellow-hat.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-outfit-1 {
    background: url("child-outfit-1.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.child-outfit-2 {
    background: url("child-outfit-2.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.child-outfit-3 {
    background: url("child-outfit-3.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.child-outfit-4 {
    background: url("child-outfit-4.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.child-outfit-5 {
    background: url("child-outfit-5.selector-icon.svg") no-repeat center center;
    background-size: 74%;
  }

  .icon.child-hairStyle-black {
    background: #f23d25 url("child-hairStyle-black.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-hairStyle-black-curly {
    background: #0d991a url("child-hairStyle-black-curly.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-hairStyle-blonde {
    background: #9954fc url("child-hairStyle-blonde.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-hairStyle-brown {
    background: #ff860d url("child-hairStyle-brown.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-hairStyle-ginger {
    background: #3a6044 url("child-hairStyle-ginger.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-accessory-lei {
    background: #5d3fba url("child-accessory-lei.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-accessory-cap {
    background: #8c0068 url("child-accessory-cap.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-accessory-choker {
    background: #43bf87 url("child-accessory-choker.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }
  .icon.child-accessory-hat {
    background: #99330b url("child-accessory-hat.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }

  .icon.child-accessory-wristbands {
    background: #0d52ff url("child-accessory-wristbands.selector-icon.svg") no-repeat center center;
    background-size: 60%;
  }
}
