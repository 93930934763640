@import "global.scss";
@import "common/items.scss";
@import "common/Animations.scss";

// Lunch Timeline mobile fixes only for -- lv language
html:lang(lv) .breaktime.Timeline h2 {
  @media only screen and (max-width: $media-md) {
    line-height: rem-calc(14);
    font-size: rem-calc(11);
  }
}

// Lunch Timeline rules only for mobile
.App .lunch.Timeline {
  @media only screen and (max-width: $media-md) {
    z-index: 3;
  }
}

.Lunch {
  background: $lunch-1;

  @media only screen and (max-width: $media-md) {
    height: rem-calc(785);
    padding-bottom: 0 !important;
  }

  @media only screen and (max-width: $media-sm) {
    height: rem-calc(685);
  }

  @media only screen and (min-width: $media-md) {
    overflow: hidden;
  }

  &:before {
    @include background-image("../elements/blobs", "blob-lunch.svg", no-repeat, center center, 65%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      background-size: rem-calc(433) rem-calc(307);
      top: rem-calc(-590);
    }
  }

  .background-elements {
    @include align-absolutePosition(auto, 0, 5vh, 0);
    width: rem-calc(323);
    height: rem-calc(221);
    z-index: +1;

    @media only screen and (max-width: $media-md) {
      top: rem-calc(518);
      bottom: auto;
      left: 50%;
      margin-left: rem-calc(208);
    }

    @media only screen and (max-width: $media-sm) {
      margin-left: rem-calc(-8);
    }

    @media only screen and (min-width: $media-md) {
      width: 40vw;
      height: 35vh;
      @include align-absolutePosition(auto, 0, 5vh, 0);
    }

    .item.chairs-table {
      @include background-image(
        "../elements/items",
        "lunch-chairs-table.svg",
        no-repeat,
        center center,
        100%,
        100%,
        55vh,
        38vh
      );

      @include align-absolutePosition(0, 0, 0, 0);

      @media only screen and (max-width: $media-md) {
        width: rem-calc(323);
        height: rem-calc(221);
        bottom: auto;
        top: 0;
      }

      @media only screen and (min-width: $media-sm) and (max-width: $media-md) {
        transform: scale(0.8);
        top: rem-calc(-60);
        margin-left: rem-calc(-120);
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(239);
        height: rem-calc(161);
        top: rem-calc(-80);
        margin-left: rem-calc(80);
      }

      @media only screen and (max-width: $media-xs) {
        margin-left: rem-calc(-30);
        width: rem-calc(209);
        height: rem-calc(131);
      }
    }
  }

  .primary-elements {
    @include align-absolutePosition(0, 0, 0, 0);
    width: 100%;
    height: rem-calc(310);
    z-index: +1;

    @media only screen and (max-width: $media-md) {
      bottom: auto;
      top: 0;
      z-index: +2;
    }

    @media only screen and (min-width: $media-md) {
      width: 55vw;
      height: 55vh;
      @include align-absolutePosition(auto, 0, 10vh, 0);
    }

    .item.table {
      @media only screen and (min-width: $media-md) {
        @include background-image(
          "../elements/items",
          "lunch-counter.svg",
          no-repeat,
          center center,
          100%,
          100%,
          100vh,
          52vh
        );
      }

      @include align-absolutePosition(auto, 0, 5%, 0);
      z-index: +1;

      @media only screen and (max-width: $media-md) {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: rem-calc(718) rem-calc(381);
        background-position: auto 10px right 500px;
      }

      > div {
        display: flex;
        justify-content: space-around;
        @include align-absolutePosition(1.5vh, 0, auto, 0);
        height: 21vh;

        @media only screen and (max-width: $media-md) {
          top: rem-calc(90);
          bottom: auto;
          left: 0;
          right: auto;
          height: rem-calc(186);
          margin: 0;
          width: 100%;
          justify-content: center;
          height: rem-calc(140);

          &.has-more-after,
          &.has-more-before {
            justify-content: flex-start;
          }
        }

        @media only screen and (max-width: $media-sm) {
          top: rem-calc(74);
          overflow: hidden;
          overflow-x: scroll;
        }
      }
    }

    .table .item {
      animation: 250ms ease-in-out 0ms 1 normal forwards running enterTable;
      opacity: 0;
      position: relative;
      display: grid;
      grid-template-rows: 1.5fr 1fr;
      align-items: flex-end;

      @media only screen and (max-width: $media-md) {
        margin: 0 1%;
      }

      @media only screen and (max-width: $media-sm) {
        margin: 0 1.5%;

        &:first-child {
          margin-left: 5%;
        }

        &:last-child {
          margin-right: 5%;
        }
      }

      &.leaveTable {
        opacity: 1;
        animation-name: leaveTable;
        animation-delay: 0ms;
      }

      label {
        position: relative;
        top: auto;
        width: min-content;
        align-self: center;
      }
    }

    .Character {
      @include align-absolutePosition(-35%, -30%, 0, 0);
    }
  }

  .item.fridge {
    @include align-absolutePosition(0, 0, 0, auto);
    width: 50vw;
    height: 70vh;

    @media only screen and (max-width: $media-md) {
      width: rem-calc(160);
      height: rem-calc(359);
      right: auto;
      left: 50%;
      margin-left: rem-calc(-19);
      top: rem-calc(339);
      bottom: auto;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    @media only screen and (min-width: $media-sm) and (max-width: $media-md) {
      transform: scale(0.8);
      top: rem-calc(-39);
      margin-left: rem-calc(-59);
    }

    &:before {
      @media only screen and (max-width: $media-md) {
        @include background-image(
          "../elements/items",
          "lunch-counter.svg",
          no-repeat,
          center center,
          100%,
          100%,
          rem-calc(718),
          rem-calc(381)
        );

        margin-left: rem-calc(-230);
        top: rem-calc(371);
        right: auto;
        bottom: auto;
        position: absolute;
        z-index: 1;
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(586);
        height: rem-calc(294);
        margin-left: rem-calc(-100);
        top: rem-calc(331);
      }

      @media only screen and (max-width: $media-xs) {
        margin-left: rem-calc(-260);
      }
    }

    @media only screen and (max-width: $media-sm) {
      margin-left: rem-calc(-219);
    }

    > div {
      @include background-image("../elements/items", "fridge.svg", no-repeat, center center, 100%, 100%, 23vh, 51vh);
      @include align-absolutePosition(auto, auto, 30%, 45%);

      @media only screen and (max-width: $media-md) {
        top: rem-calc(99);
        left: 0;
        width: rem-calc(190);
        height: rem-calc(420);
        left: 50%;
        margin-top: rem-calc(200);
      }

      @media only screen and (max-width: $media-sm) {
        width: rem-calc(131);
        height: rem-calc(293);
        margin-left: rem-calc(190);
        top: rem-calc(59);
      }

      @media only screen and (max-width: $media-xs) {
        margin-left: rem-calc(150);
      }
    }

    .fridge-leak {
      @include background-image("../elements/items", "fridge-leak.svg", no-repeat, center center, 100%, 100%, 4vh, 3vh);
      @include align-absolutePosition(auto, auto, 30%, 80%);
      animation: scale-in-tl 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
    }
  }

  button.fridgeDoor {
    @include align-absolutePosition(17%, 2%, auto, auto);
    width: 20vh;
    height: 39vh;

    @media only screen and (max-width: $media-md) {
      width: rem-calc(166);
      height: rem-calc(323);
    }

    @media only screen and (max-width: $media-sm) {
      width: rem-calc(117);
      height: rem-calc(229);
      top: rem-calc(2);
      left: rem-calc(12);
    }

    &:hover svg {
      filter: drop-shadow(0 0 5px royalblue);
    }

    &:disabled:hover svg {
      filter: none;
    }

    svg {
      height: 100%;
      width: 100%;
      transform-origin: left;
      transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-25deg);
      transition: all 0.25s ease-in-out;
    }
    &:disabled svg {
      transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(0deg);
    }
    &:disabled .fridge-leak {
      display: none;
    }
  }

  .inventory {
    @include align-absolutePosition(auto, 0, 14vh, 0);
    .items {
      background: rgba(143, 108, 102, 0.85);
      @media only screen and (max-width: $media-md) {
        justify-content: space-evenly !important;
      }
      @media only screen and (max-width: $media-xs) {
        justify-content: center !important;
      }
    }

    @media only screen and (max-width: $media-md) {
      position: fixed;
    }
  }

  // has-more after & before
  .has-more-before:before,
  .has-more-after:after {
    background-color: $lunch--1;
  }
}

// Battery rules only for Lunch scene
.App .scene.Lunch .Battery {
  @media only screen and (max-width: $media-md) {
    height: rem-calc(250);
    bottom: rem-calc(-90);
    left: rem-calc(78);
  }
}
