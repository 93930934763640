@import "global.scss";
@import "common/items.scss";
@import "common/Inventory.scss";

.Breakfast.scene {
  background: $green-0;

  @media only screen and (max-width: $media-md) {
    height: rem-calc(975);
  }

  @media only screen and (max-width: $media-sm) {
    height: rem-calc(790);
  }

  @media only screen and (max-width: $media-xs) {
    height: rem-calc(635);
  }

  &:before {
    @include background-image("../elements/blobs", "blob-breakfast.svg", no-repeat, center center, 80%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      top: rem-calc(60);
      background-position: top center;
      background-size: rem-calc(817) rem-calc(400);
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(60);
      background-position: top center;
      background-size: rem-calc(517) rem-calc(300);
    }

    @media only screen and (max-width: $media-xs) {
      top: rem-calc(30);
    }
  }

  .background-elements {
    @include align-absolutePosition(0, auto, 0, 0);
    width: 50vw;
    height: 70vh;

    @media only screen and (max-width: $media-md) {
      width: 50%;
      height: rem-calc(539);
      bottom: auto;
      top: rem-calc(160);
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(50);
    }

    .item.frame {
      @include background-image(
        "../elements/items",
        "breakfast-frame.svg",
        no-repeat,
        center center,
        100%,
        100%,
        15vh,
        14vh
      );

      @include align-absolutePosition(5%, 60%, auto, auto);

      @media only screen and (max-width: $media-md) {
        right: auto;
        top: rem-calc(230);
        left: 40%;
        width: rem-calc(119);
        height: rem-calc(111);
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(230);
        left: auto;
        right: 0;
        margin-right: rem-calc(98);
        width: rem-calc(76);
        height: rem-calc(78);
      }

      @media only screen and (max-width: $media-xs) {
        width: rem-calc(50);
        height: rem-calc(53);
        margin-right: rem-calc(88);
      }
    }

    .item.plant {
      @include background-image(
        "../elements/items",
        "breakfast-plant.svg",
        no-repeat,
        center center,
        100%,
        100%,
        11vh,
        28vh
      );

      @include align-absolutePosition(auto, 78%, 0, auto);

      @media only screen and (max-width: $media-md) {
        right: auto;
        top: rem-calc(543);
        left: 40%;
        width: rem-calc(87);
        height: rem-calc(222);
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(447);
        width: rem-calc(66);
        height: rem-calc(163);
        left: auto;
        right: 0;
        margin-right: rem-calc(118);
      }

      @media only screen and (max-width: $media-xs) {
        top: rem-calc(327);
        width: rem-calc(40);
        height: rem-calc(106);
        margin-right: rem-calc(108);
      }
    }
  }

  .primary-elements {
    width: 100%;
    height: rem-calc(420);

    @media only screen and (min-width: $media-md) {
      @include align-absolutePosition(0, auto, 0, 0);
      width: 50vw;
      height: 70vh;
    }

    .item.table {
      @include align-absolutePosition(auto, 0, 0, auto);
      z-index: +1;
      @media only screen and (min-width: $media-md) {
        @include background-image(
          "../elements/items",
          "breakfast-table.svg",
          no-repeat,
          center center,
          100%,
          100%,
          71vh,
          35vh
        );
      }

      @media only screen and (max-width: $media-md) {
        width: 100%;
        bottom: auto;
        top: rem-calc(80);
        right: auto;
        left: 0;
      }

      > div {
        display: flex;

        height: rem-calc(140);
        @include align-absolutePosition(0, 0, auto, 0);

        @media only screen and (min-width: $media-xs) {
          justify-content: space-evenly;
        }

        @media only screen and (min-width: $media-sm) {
          height: rem-calc(188);
        }

        @media only screen and (min-width: $media-md) {
          @include align-absolutePosition(-10vh, 0, auto, 0);
          height: 22vh;
          justify-content: center;
        }

        @media only screen and (min-width: $media-xs) and (max-width: $media-md) {
          justify-content: center;
        }

        @media only screen and (max-width: $media-sm) {
          overflow: hidden;
          overflow-x: scroll;
          top: rem-calc(12);
          justify-content: flex-start;

          &.has-more-before,
          &.has-more-after {
            justify-content: flex-start;
            overflow: hidden;
            overflow-x: scroll;
          }
        }
      }

      @media only screen and (max-width: $media-xs) {
        top: rem-calc(64);
      }
    }

    .table .item {
      animation: 250ms ease-in-out 0ms 1 normal forwards running enterTable;
      opacity: 0;
      position: relative;
      display: grid;
      grid-template-rows: 1fr 1fr;
      align-items: flex-end;

      @media only screen and (max-width: $media-md) {
        height: rem-calc(190);
      }

      @media only screen and (min-width: $media-sm) {
        margin: 0 rem-calc(10);
      }

      @media only screen and (max-width: $media-sm) {
        height: rem-calc(130);
      }

      @media only screen and (min-width: $media-xs) and (max-width: $media-sm) {
        margin-left: rem-calc(4);
        margin-right: rem-calc(4);
        width: rem-calc(130);
      }

      @media only screen and (max-width: $media-xs) {
        margin: 0 rem-calc(8);
      }

      &.leaveTable {
        opacity: 1;
        animation-name: leaveTable;
        animation-delay: 0ms;
      }

      label {
        position: relative;
        top: auto;
        width: min-content;
        align-self: center;

        @media only screen and (max-width: $media-sm) {
          width: calc(100% - rem-calc(25));
        }
      }
    }

    .Character {
      @include align-absolutePosition(0, -20%, -15.5%, 0);

      &:before {
        @include background-image(
          "../elements/blobs",
          "character-breakfast-dinner-cooking-shadow.svg",
          no-repeat,
          center center,
          100%,
          100%,
          28vh,
          10vh
        );
        @include align-absolutePosition(auto, 10%, -5%, -17%);
      }

      &.child {
        &:before {
          left: -25%;
        }
      }
    }
  }

  .item.sink {
    @include align-absolutePosition(0, 0, 0, auto);
    width: 50vw;
    height: 70vh;

    @media only screen and (max-width: $media-md) {
      width: 50%;
      height: rem-calc(556);
      bottom: auto;
      top: rem-calc(170);
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(59);
    }

    > div {
      @include background-image(
        "../elements/items",
        "breakfast-kitchen.svg",
        no-repeat,
        center center,
        100%,
        100%,
        40.5vh,
        59.5vh
      );
      @include align-absolutePosition(0, auto, auto, 20%);

      @media only screen and (max-width: $media-md) {
        left: auto;
        right: 40%;
        top: rem-calc(170);
        width: rem-calc(322);
        height: rem-calc(473);
      }

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(170);
        right: auto;
        left: 0;
        width: rem-calc(269);
        height: rem-calc(398);
        margin-left: rem-calc(-45);
      }

      @media only screen and (max-width: $media-xs) {
        width: rem-calc(206);
        height: rem-calc(310);
        margin-left: rem-calc(-60);
      }
    }
  }

  .item.faucet {
    @include align-absolutePosition(56.5%, 63%, auto, auto);
    width: 6vh;
    height: 7vh;

    @media only screen and (max-width: $media-md) {
      width: rem-calc(47);
      height: rem-calc(55);
      top: rem-calc(270);
    }

    @media only screen and (max-width: $media-sm) {
      top: rem-calc(226);
      width: rem-calc(37);
      height: rem-calc(45);
      left: rem-calc(35);
    }

    @media only screen and (max-width: $media-xs) {
      top: rem-calc(166);
    }

    svg {
      height: 100%;
      width: 100%;
    }

    &:disabled svg .drop {
      display: none;
    }
  }

  .inventory {
    @include align-absolutePosition(auto, 0, 8vh, 0);

    @media only screen and (max-width: $media-md) {
      position: fixed;
      bottom: rem-calc(96);
    }

    .items {
      background: rgba(110, 143, 121, 0.85);
      @media only screen and (max-width: $media-sm) {
        justify-content: space-around !important;
      }
      @media only screen and (max-width: $media-xs) {
        justify-content: center !important;
      }
    }
  }

  // has-more after & before
  .has-more-before:before,
  .has-more-after:after {
    background-color: $green-2;
  }
}
