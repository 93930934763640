@import "global.scss";

/* Non react friendly code. Uses ids and is not componentised. */

// character outer only for mobile
//-----------------------------------------------
#open-character-modal-button,
#close-character-modal-button,
#character-overlay-modal-close {
  @media only screen and (min-width: $media-md) {
    display: none;
  }
}

.character-rounded {
  // character is hidden
  //-----------------------------------------------------
  &#open-character-modal:not(:target) {
    @media only screen and (max-width: $media-md) {
      bottom: rem-calc(17);
      right: rem-calc(110);
      width: rem-calc(54);
      height: rem-calc(54);
      overflow: hidden;
      border-radius: 50%;
      background: white;
      position: fixed;
      z-index: 99;
      box-shadow: 3px 2px 0px 0px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }

    @media only screen and (max-width: $media-sm) {
      right: rem-calc(90);
    }

    @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
      right: rem-calc(80);
      width: rem-calc(33);
      height: rem-calc(33);
      bottom: rem-calc(16);
    }

    // open character modal button
    .open-character-modal-button {
      @media only screen and (max-width: $media-md) {
        width: rem-calc(54);
        height: rem-calc(54);
        position: absolute;
        z-index: 12;
        font-size: 0;
      }
    }
    // close character modal button & character overlay modal close
    .close-character-modal-button,
    .character-overlay-modal-close {
      @media only screen and (max-width: $media-md) {
        display: none;
      }
    }
  }

  // character is open
  //-----------------------------------------------------
  &#open-character-modal:target {
    @media only screen and (max-width: $media-md) {
      bottom: rem-calc(17);
      right: rem-calc(110);
      width: 90%;
      height: 80%;
      height: calc(100% - rem-calc(115));
      border-radius: 20px;
      left: 5%;
      right: 0 !important;
      top: 0;
      bottom: auto !important;
      overflow: visible;
      background: none;
      box-shadow: none;
      cursor: pointer;
    }

    // overlay background
    .character-overlay-modal-close {
      font-size: 0;

      @media only screen and (max-width: $media-md) {
        @include align-absolutePosition(0, 0, auto, 0);
        background-color: rgba($black, 0.7);
        z-index: 3;
        height: calc(100% - rem-calc(88));
        position: fixed;
      }

      @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
        height: calc(100% - rem-calc(68));
      }
    }

    // close character modal button
    .close-character-modal-button {
      @media only screen and (max-width: $media-md) {
        @include align-absolutePosition(auto, rem-calc(107), rem-calc(15), auto);
        position: fixed;
        z-index: 4;
        background-image: url("/elements/buttons/icon-button-menu-close.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: rem-calc(55);
        height: rem-calc(55);
        font-size: 0;
      }

      @media only screen and (max-width: $media-sm) {
        right: rem-calc(90);
      }

      @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
        width: rem-calc(33);
        height: rem-calc(33);
        right: rem-calc(79);
      }
    }

    // open character modal button
    .open-character-modal-button {
      @media only screen and (max-width: $media-md) {
        display: none;
      }
    }
  }
}

.character-rounded .Character {
  @media only screen and (max-width: $media-md) {
    transform: scale(0.6);
    right: auto;
    bottom: auto !important;
    z-index: 11;
  }

  // adult
  &.adult {
    @media only screen and (max-width: $media-md) {
      width: rem-calc(160) !important;
      height: rem-calc(505) !important;
      top: rem-calc(-87) !important;
      left: rem-calc(-52) !important;
    }
  }

  // child
  &.child {
    @media only screen and (max-width: $media-md) {
      width: rem-calc(145) !important;
      height: rem-calc(440) !important;
      top: rem-calc(-79) !important;
      left: rem-calc(-44) !important;
    }
  }
}

#open-character-modal:not(:target) .Character {
  @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
    transform: scale(0.4);
  }

  // adult

  &.adult {
    @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
      top: rem-calc(-141) !important;
      left: rem-calc(-63) !important;
      right: auto !important;
    }
  }

  // child
  &.child {
    @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
      top: rem-calc(-127) !important;
      left: rem-calc(-55) !important;
      right: auto !important;
    }
  }
}

#open-character-modal:target .Character {
  @media only screen and (max-width: $media-md) {
    right: auto;
    left: 50% !important;
    top: 5% !important;
    margin-left: rem-calc(-73) !important;
    z-index: 99;
  }

  @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
    transform: scale(0.5);
    top: rem-calc(-60) !important;
  }
}

#open-character-modal:target .Character:after {
  @media only screen and (max-width: $media-md) {
    content: "";
    @include align-absolutePosition(-14.3%, -10%, -14.3%, 50%);
    background-color: $white;
    z-index: -44;
    width: rem-calc(680);
    margin-left: rem-calc(-350);
    border-radius: rem-calc(30);
  }

  @media only screen and (max-width: $media-sm) {
    margin-left: rem-calc(-229);
    width: rem-calc(440);
  }
}

// character --- ONLY FOR DinnerCooking
.DinnerCooking .character-rounded:not(:target) .Character {
  // adult
  &.adult {
    @media only screen and (max-width: $media-md) {
      top: rem-calc(-82) !important;
    }
  }

  // child
  &.child {
    @media only screen and (max-width: $media-md) {
      top: rem-calc(-82) !important;
    }
  }
}

// character --- ONLY FOR Supermarket
.Supermarket .character-rounded:not(:target) .Character {
  // adult
  &.adult {
    @media only screen and (max-width: $media-md) {
      //top: rem-calc(205) !important;
    }
  }
}

// bag - on - open character modal
.character-rounded#open-character-modal:target {
  .bag {
    @media only screen and (max-width: $media-md) {
      position: fixed;
      z-index: 99;
      top: 5%;
      left: 50%;
      width: rem-calc(261);
      height: rem-calc(387);
      margin-left: rem-calc(-132);
      right: auto;
      bottom: auto;
    }

    @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
      top: 0;
    }
  }
  .bag .item.reusable-bag {
    @media only screen and (max-width: $media-md) {
      left: 50%;
      bottom: 0;
      top: rem-calc(143);
      right: auto;
      bottom: auto;
      z-index: 99;
      margin-left: rem-calc(-42);
      width: rem-calc(25);
      height: rem-calc(176);
      position: absolute;
    }

    @media only screen and (max-height: $media-sm-height) and (max-width: $media-md) {
      transform: scale(0.85);
      top: rem-calc(86);
      margin-left: rem-calc(-35);
    }
  }
}
