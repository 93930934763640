@use "sass:math"; // used in order to include division and replace "/" in calculations outside calc().

/******** Font Families ********/

/* ubuntu-regular - latin_greek_cyrillic */
@font-face {
  font-family: "Ubuntu-Normal";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/ubuntu-v19-latin_greek_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("fonts/ubuntu-v19-latin_greek_cyrillic-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/ubuntu-v19-latin_greek_cyrillic-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/ubuntu-v19-latin_greek_cyrillic-regular.woff") format("woff"),
    /* Modern Browsers */ url("fonts/ubuntu-v19-latin_greek_cyrillic-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("fonts/ubuntu-v19-latin_greek_cyrillic-regular.svg#Ubuntu") format("svg"); /* Legacy iOS */
}
/* ubuntu-italic - latin_greek_cyrillic */
@font-face {
  font-family: "Ubuntu-Italic";
  font-style: italic;
  font-weight: 400;
  src: url("fonts/ubuntu-v19-latin_greek_cyrillic-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("fonts/ubuntu-v19-latin_greek_cyrillic-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/ubuntu-v19-latin_greek_cyrillic-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/ubuntu-v19-latin_greek_cyrillic-italic.woff") format("woff"),
    /* Modern Browsers */ url("fonts/ubuntu-v19-latin_greek_cyrillic-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("fonts/ubuntu-v19-latin_greek_cyrillic-italic.svg#Ubuntu") format("svg"); /* Legacy iOS */
}
/* ubuntu-700 - latin_greek_cyrillic */
@font-face {
  font-family: "Ubuntu-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/ubuntu-v19-latin_greek_cyrillic-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("fonts/ubuntu-v19-latin_greek_cyrillic-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/ubuntu-v19-latin_greek_cyrillic-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/ubuntu-v19-latin_greek_cyrillic-700.woff") format("woff"),
    /* Modern Browsers */ url("fonts/ubuntu-v19-latin_greek_cyrillic-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("fonts/ubuntu-v19-latin_greek_cyrillic-700.svg#Ubuntu") format("svg"); /* Legacy iOS */
}

/******** Font Icons ********/

@font-face {
  font-family: "2050";
  src: url("fonts/icons/2050.svg") format("svg");
  src: url("fonts/icons/2050.woff2") format("woff2"), url("fonts/icons/2050.ttf") format("truetype"),
    url("fonts/icons/2050.woff") format("woff");

  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
}

// Rem calc rules
//------------------------------------------------------------------------

//* @group ANCHOR STRIP UNIT *//

// USE: It strips the unit of measure and returns it.
// AUTHOR: FOUNDATION

@function strip-unit($num) {
  @return math.div($num, ($num * 0 + 1));
}

//* @group ANCHOR CONVERT TO REM *//

// USE: Converts a number to rem.
// AUTHOR: FOUNDATION

$rem-base: 16px;

@function convert-to-rem($value, $base-value: $rem-base) {
  $value: math.div(strip-unit($value), strip-unit($base-value)) * 1rem;
  @if ($value == 0rem) {
    $value: 0;
  } // Turn 0rem into 0
  @return $value;
}

//* @group ANCHOR REM CALC *//

// USE: Combination of the above functions in order to convert any number to rem.
// AUTHOR: FOUNDATION

@function rem-calc($values, $base-value: 16px) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

// Rem calc rules --- Ends
//------------------------------------------------------------------------

// media query - screen sizes
$media-sm-height: rem-calc(490); // 590
$media-xs: rem-calc(480);
$media-sm: rem-calc(767);
$media-md: rem-calc(1100); // 1220

/******** Colours ********/

$blue-0: #deeaf5;
$blue-1: #def5f5;

$yellow-0: #f4f5de;

$green-0: #def5e6;
$green-1: #e9f5de;
$green-2: #465b4d;

$pink-0: #f6e8f1;
$pink-1: #f5e1de;

$white: #ffffff;
$black: #000000;

$text-color: #212121;

$grey--1: #333333;
$grey: #4c4c4c;
$grey-1: #e5e5e5;
$grey-2: #f2f2f2;
$grey-3: #c6c6c6;

$character-1: #e5e5e5;
$character-0: #e0e0e0;
$character--1: #555a57;

$breakfast-2: #cfe7d7;
$breakfast-1: #def5e6;
$breakfast-0: #cee3d5;
$breakfast--1: #465b4d;

$transport-2: #d6ecec;
$transport-1: #def5f5;
$transport-0: #cfe3e3;
$transport--1: #475756;

$lunch-2: #ecd9d6;
$lunch-1: #f5e1de;
$lunch-0: #e3d1cf;
$lunch--1: #5b4541;

$breaktime-1: #deeaf5;
$breaktime-0: #cfd9e3;
$breaktime--1: #434b60;

$snacking-1: #f6e0ee;
$snacking-0: #e3cfdc;
$snacking--1: #5e4655;

$supermarket-2: #dedfc4;
$supermarket-1: #f4f5de;
$supermarket-0: #e2e3cf;
$supermarket--1: #4e4f41;

$home-1: #e9f5de;
$home-0: #d8e3cf;
$home--1: #4a5441;

$restaurant-2: #cedac1;
$restaurant-1: #dedff5;
$restaurant-0: #cfd0e3;
$restaurant--1: #4f4e64;

$dinner-cooking: #bad0c2;

$dinner-delivery: #b3b5cf;

$breaktime: #b5c5d3;

$Snacking: #ddc7d6;

$battery-red: #ff6446;
$battery-orange: #ffa800;
$battery-green: #52ce6a;

/******** Mixins ********/

// Position Absolute

@mixin align-absolutePosition($top: 0px, $right: 0px, $bottom: 0px, $left: 0px) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  margin: auto;
}

// Background SVGS

@mixin background-image(
  $path--rel,
  $img,
  $background-repeat,
  $background-position,
  $width-size,
  $height-size,
  $width: null,
  $height: null
) {
  display: block;
  content: " ";
  background-image: url("#{$path--rel}/#{$img}");
  background-repeat: $background-repeat;
  background-position: $background-position;
  background-size: $width-size $height-size;
  width: $width;
  height: $height;
}

// shows text only for screen readers
.show-for-sr {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

/******** Placeholders ********/

%no-text {
  text-indent: -5000rem;
}

%no-space {
  padding: 0;
  margin: 0;
}

%transition {
  transition: all 0.3s ease-in-out;
}

%list-neutralizer {
  @extend %no-space;
  list-style: none;
}

%inline-list {
  @extend %list-neutralizer;
  display: flex;
  align-items: center;
}

%in-game-button {
  label {
    @include align-absolutePosition(7vh, 0, auto, 0);
    background: $white;
    color: $text-color;

    padding: rem-calc(8) rem-calc(12);
    border-radius: 20px;
    font-size: rem-calc(14);

    @media only screen and (min-width: $media-md) {
      padding: 0.5vh 1vh;
      border-radius: 1vh;
      font-size: 1.5vh;
    }

    @media only screen and (max-width: $media-sm) {
      font-size: rem-calc(12);
      border-radius: rem-calc(6);
    }
  }
  &:active {
    transform: none;
  }
}

%white-button {
  background: $white;
  color: $text-color;
  padding: rem-calc(18) rem-calc(26);
  border-radius: rem-calc(18);
  font-size: rem-calc(16);
  box-shadow: rem-calc(3) rem-calc(3) 0 $grey;

  @media only screen and (min-width: $media-md) {
    padding: 2vh 3vh;
    border-radius: 2vh;
    font-size: 1.8vh;
    box-shadow: 0.35vh 0.35vh 0 $grey;
  }
}

/******** Resets ********/
button {
  font-family: "Ubuntu-Bold", sans-serif;
  font-size: calc(32px);
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @extend %transition;

  &:hover {
    filter: contrast(80%) brightness(100%);
  }

  &:active {
    @extend %transition;
    transform: scale(1.2);
    transform-origin: center center;
  }

  &:disabled {
    pointer-events: none;
  }
}

p:last-of-type {
  margin-bottom: 0;
}

/******** Font Sizes ********/

$font-size--3: 3rem;
$font-size--2: 2.6rem;
$font-size--1: 1.9rem;
$font-size-0: 1.15rem;
$font-size-1: 1rem;
$font-size-2: 0.9rem;

/******** Font Icons ********/

$common-icons: (
  logo: "\e902",
  previous: "\e900",
  next: "\e901"
);

// For adding font icons to elements using CSS pseudo-elements.
// Attach a particular icon to an element complete with all the required styles.
// Attach just the required icon styles to a bunch of elements, without setting a particular icon.
// Set custom styles for your icon pseudo-element.

//EXAMPLE:  @include get-icon(before, $common-icons, search, white, 1rem, 1.3, true);

@mixin get-icon(
  $position: before,
  $map: $common-icons,
  $icon: default,
  $color: black,
  $font-size: 1rem,
  $line-height: inherit,
  $only-icon: false
) {
  $content: map-get($map, $icon);

  @if $position == both {
    $position: "before, &:after";
  }

  @if $only-icon == true {
    font-size: 0px;
  }

  &:#{$position} {
    content: $content;
    color: $color;
    font-size: $font-size;
    line-height: $line-height;

    // Required icon styles
    font-family: "2050";
    font-display: block;
    font-weight: normal;
    font-style: normal;
  }
}

/******** Text Styles ********/

h1 {
  font-family: "Ubuntu-Bold";
  font-size: $font-size--3;
  @extend %no-space;
}

h2 {
  font-size: $font-size--2;
  @extend %no-space;
}

h3 {
  font-size: $font-size-0;
  @extend %no-space;
}

p,
li {
  font-size: $font-size-1;
  line-height: 1.5rem;
}

/******** Scene Colours ********/

$scene-colours: (
  Intro: $blue-1,
  CharacterSetUp: $grey-1,
  Breakfast: $green-0,
  Transportation: $blue-1
);

@each $name, $code in $scene-colours {
  .#{$name}.scene {
    background: $code !important;
  }
}

/******** Timeline Colours ********/

$timeline-colours: (
  character-set-up: $character--1,
  breakfast: $breakfast--1,
  transportation: $transport--1,
  lunch: $lunch--1,
  breaktime: $breaktime--1,
  snacking: $snacking--1,
  supermarket: $supermarket--1,
  dinner-selection: $home--1,
  dinner-delivery: $restaurant--1,
  dinner-cooking: $breakfast--1
);

@each $name, $code in $timeline-colours {
  .#{$name} .inventory {
    background: $code !important;
  }
}

/******** Timeline Dot Colours ********/

$timeline-dot-colours: (
  character-set-up: $grey-1,
  breakfast: $green-0,
  transportation: $blue-1,
  lunch: $pink-1,
  breaktime: $breaktime-1,
  snacking: $snacking-1,
  supermarket: $supermarket-1,
  dinner-selection: $green-1,
  dinner-delivery: $restaurant-1,
  dinner-cooking: $green-0
);

$eu-langs: bg, cs, da, de, el, en, es, et, fi, fr, ga, hr, hu, it, lt, lv, mt, nl, pl, pt, ro, sk, sl, sv;
