@import "global.scss";

// Note that common modal CSS is implemented in App.scss.
.BadgeAwarded {
  width: 80%;
  height: fit-content;
  // modal --- only for mobile
  @media only screen and (max-width: $media-md) {
    padding-top: rem-calc(32);
    padding-bottom: rem-calc(32);
  }

  @media only screen and (min-width: $media-md) {
    width: 40vw;
    height: 25vh;
    padding: 2% 0;
  }
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  .badge-text {
    flex-basis: 60%;
  }

  .badge {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-around;
    &:before {
      display: block;
      content: " ";
      background-size: auto 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  .close {
    filter: invert(80%);
    &:hover {
      filter: invert(100%);
    }
  }
}
