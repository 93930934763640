@import "global.scss";
@import "common/items.scss";
@import "common/Animations.scss";

.App .scene.DinnerSelection .Battery {
  @media only screen and (min-width: $media-md) {
    z-index: 1;
  }
}

.DinnerSelection {
  background: $green-1;

  @media only screen and (max-width: $media-sm) {
    height: rem-calc(705);
  }
  @media only screen and (min-width: $media-sm) {
    overflow: hidden;
  }

  &:before {
    @include background-image("../elements/blobs", "blob-dinner-selection.svg", no-repeat, center center, 80%, 80%);
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (max-width: $media-md) {
      background-size: rem-calc(705) rem-calc(305);
      height: rem-calc(405);
      top: rem-calc(100);
      bottom: auto;
    }

    @media only screen and (max-width: $media-sm) {
      background-size: rem-calc(683) rem-calc(402);
      background-color: $green-1;
    }
  }

  .map {
    @include align-absolutePosition(0, 0, 0, 0);

    @media only screen and (min-width: $media-sm) {
      width: 85vw;
      height: 85vh;
    }

    @media only screen and (min-width: $media-sm) and (max-width: $media-md) {
      transform: scale(1.4);
      top: rem-calc(220);
    }

    > div {
      animation: swing-in-top-fwd 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;

      @media only screen and (min-width: $media-sm) {
        @include background-image(
          "../elements/items",
          "dinner-map.svg",
          no-repeat,
          center center,
          100%,
          100%,
          145vh,
          71vh
        );
      }

      @include align-absolutePosition(7vh, 0, auto, 0);
      z-index: +1;
      background-size: cover;

      @media only screen and (max-width: $media-md) {
        width: rem-calc(503);
        height: rem-calc(247);
      }

      @media only screen and (max-width: $media-sm) {
        width: 100%;
        height: 100%;
      }

      @media only screen and (min-width: $media-sm) and (max-width: $media-md) {
        top: 50%;
        margin-top: rem-calc(-220);
      }
    }

    // Maps only for mobile
    > div:before,
    > div:after {
      content: "";
      @media only screen and (max-width: $media-sm) {
        @include background-image(
          "../elements/items",
          "dinner-map.svg",
          no-repeat,
          center center,
          100%,
          100%,
          rem-calc(503),
          rem-calc(247)
        );

        z-index: +1;
        background-size: cover;
      }
    }

    > div:before {
      @include align-absolutePosition(rem-calc(84), rem-calc(-180), auto, auto);

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(40);
      }

      @media only screen and (max-width: $media-xs) {
        top: rem-calc(20);
      }
    }

    > div:after {
      @include align-absolutePosition(rem-calc(404), auto, auto, rem-calc(-220));

      @media only screen and (max-width: $media-sm) {
        top: rem-calc(290);
      }

      @media only screen and (max-width: $media-xs) {
        top: rem-calc(290);
      }
    }
  }

  .item {
    label {
      top: 40vh !important;
      width: min-content;
      padding: 1.5vh 2vh !important;

      @media only screen and (min-width: $media-sm) and (max-width: $media-md) {
        padding: rem-calc(12) rem-calc(14) !important;
        font-size: rem-calc(10) !important;
        border-radius: rem-calc(10) !important;
      }

      @media only screen and (max-width: $media-sm) {
        font-size: rem-calc(14) !important;
        width: rem-calc(218);
        margin-left: rem-calc(-70) !important;
      }
    }

    &.food-delivery {
      @include align-absolutePosition(auto, auto, 23vh, 29vh);
      animation: bounce-in-top 1.1s both;
      animation-delay: 0.4s;

      @media only screen and (max-width: $media-md) {
        left: rem-calc(330);
        top: rem-calc(-20);
        bottom: auto;
      }

      @media only screen and (max-width: $media-sm) {
        z-index: 3;
        left: auto;
        right: rem-calc(120);
        top: rem-calc(38);
      }

      @media only screen and (max-width: $media-xs) {
        top: rem-calc(22);
      }
    }

    &.cooking-at-home {
      @include align-absolutePosition(auto, auto, 30vh, 98vh);
      animation: bounce-in-top 1.1s both;
      animation-delay: 0.8s;

      @media only screen and (max-width: $media-md) {
        left: rem-calc(88);
        top: 0;
        bottom: auto;
      }

      @media only screen and (max-width: $media-sm) {
        z-index: 3;
        left: rem-calc(105);
        top: rem-calc(272);
        bottom: auto;
      }

      @media only screen and (max-width: $media-xs) {
        top: rem-calc(262);
      }
    }

    &:not(.selected):disabled {
      filter: grayscale(100%) contrast(50%) brightness(140%);
    }
  }
}

.App .DinnerSelection.scene button.item label {
  @media only screen and (max-width: $media-md) {
    top: rem-calc(180) !important;
  }

  @media only screen and (max-width: $media-sm) {
    top: rem-calc(170) !important;
  }
}
