@import "global.scss";

.MapTransition {
  background: $blue-1;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    background: $blue-1;
    z-index: 0;
  }

  @media only screen and (max-width: $media-md) {
    display: flex;
    align-items: center;
  }

  .map {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: $media-md) {
      overflow: visible;
    }

    &:before,
    &:after {
      @media only screen and (max-width: $media-md) {
        @include align-absolutePosition(auto, 0, 0, 0);
        content: "";
        width: rem-calc(3020);
        position: absolute;
        z-index: 99;
        top: 50%;
        background-repeat: no-repeat;
        transition: all 4s ease-in-out;
        transition-property: all;
        transition-timing-function: ease-in-out;
        transition-duration: 4s;
        transition-delay: 1.9s;
      }
    }

    &:before {
      @media only screen and (max-width: $media-md) {
        height: rem-calc(571);
        margin-top: rem-calc(-350);
        background-image: url(../elements/items/map-mobile-background-1.svg);
      }
    }

    &:after {
      @media only screen and (max-width: $media-md) {
        height: rem-calc(371);
        margin-top: rem-calc(155);
        background-color: $blue-1;
        background-image: url(../elements/items/map-mobile-background-2.svg);
      }
    }

    svg {
      @include align-absolutePosition(auto, 0, 0, 0);
      width: auto;
      height: 100%;
      margin: 0;
      transition: all 4s ease-in-out;
    }

    @media only screen and (max-width: $media-md) {
      width: rem-calc(3020);
      height: rem-calc(310);
    }

    [class*="outline"] {
      display: none;
    }

    // from breakfast
    &.from-breakfast:before,
    &.from-breakfast:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(50);
        transition-property: all;
        transition-duration: 4s;
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-50);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-140);
      }
    }

    &.from-breakfast svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(0);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(50);
        transition-property: all;
        transition-duration: 4s;
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-50);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-140);
      }
    }

    // from transportation

    &.from-transportation:before,
    &.from-transportation:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(-200);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-290);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-520);
      }
    }

    &.from-transportation svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-14%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-200);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-290);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-520);
      }
    }

    // character - adult - from-lunch

    &.character-type-adult.from-lunch:before,
    &.character-type-adult.from-lunch::after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(-1850);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1950);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-2150);
      }
    }

    &.character-type-adult.from-lunch svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-69%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-1850);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1950);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-2150);
      }
    }

    // character - child - from-lunch
    &.character-type-child.from-lunch:before,
    &.character-type-child.from-lunch:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(-700);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-950);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-990);
      }
    }

    &.character-type-child.from-lunch svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-30%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-700);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-950);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-990);
      }
    }

    // dinner - selection
    &.from-dinner-selection:before,
    &.from-dinner-selection:after {
      @media only screen and (max-width: $media-md) {
        transition-property: all;
        transition-duration: 4s;
        left: rem-calc(-1500);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1550);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-1690);
      }
    }

    &.from-dinner-selection svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-62%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-1500);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1550);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-1690);
      }
    }

    // breaktime
    &.character-type-child.from-breaktime:before,
    &.character-type-child.from-breaktime:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(50);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-50);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-140);
      }
    }

    &.character-type-child.from-breaktime svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(0);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(50);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-50);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-140);
      }
    }

    // to transportation

    &.to-transportation:before,
    &.to-transportation:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(-200);
      }

      @media only screen and (max-width: $media-md) {
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-320);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-520);
      }
    }

    &.to-transportation svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-14%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-200);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-320);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-520);
      }

      // travelling duration
      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: 4s;
      transition-delay: 1.9s;

      .home {
        animation: slide-in-fwd-center 0.5s linear;
      }

      .home-outline {
        animation: fade-in 1.5s linear both;
        animation-delay: 0.3s;
        display: block;
      }

      .home-sign {
        animation: flicker 1s linear;
      }

      .station {
        animation: slide-in-fwd-center 0.5s linear;
        animation-delay: 6s;
      }

      .station-outline {
        animation: fade-in 1.5s linear both infinite;
        animation-delay: 6.3s;
        display: block;
      }

      .station-sign {
        animation: flicker 1s linear both;
        animation-delay: 6.8s;
      }
    }

    // character - adult - lunch

    &.character-type-adult.to-lunch:before,
    &.character-type-adult.to-lunch:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(-1850);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1950);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-2150);
      }
    }

    &.character-type-adult.to-lunch svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-69%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-1850);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1950);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-2150);
      }

      // travelling duration
      transition-property: all;
      transition-duration: 4s;
      transition-delay: 1.9s;

      .station {
        animation: slide-in-fwd-center 0.5s linear;
      }

      .station-outline {
        animation: fade-in 1.5s linear both;
        animation-delay: 0.3s;
        display: block;
      }

      .station-sign {
        animation: flicker 1s linear;
      }

      .company {
        animation: slide-in-fwd-center 0.5s linear;
        animation-delay: 6s;
      }

      .company-outline {
        animation: fade-in 1.5s linear both infinite;
        animation-delay: 6.3s;
        display: block;
      }

      .company-sign {
        animation: flicker 1s linear both;
        animation-delay: 6.8s;
      }
    }

    // character - child - lunch

    &.character-type-child.to-lunch:before,
    &.character-type-child.to-lunch:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(-700);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-950);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-990);
      }
    }

    &.character-type-child.to-lunch svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-30%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-700);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-950);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-990);
      }

      // travelling duration
      transition-property: all;
      transition-duration: 4s;
      transition-delay: 1.9s;

      .station {
        animation: slide-in-fwd-center 0.5s linear;
      }

      .station-outline {
        animation: fade-in 1.5s linear both;
        animation-delay: 0.3s;
        display: block;
      }

      .station-sign {
        animation: flicker 1s linear;
      }

      .school {
        animation: slide-in-fwd-center 0.5s linear;
        animation-delay: 6s;
      }

      .school-outline {
        animation: fade-in 1.5s linear both infinite;
        animation-delay: 6.3s;
        display: block;
      }

      .school-sign {
        animation: flicker 1s linear both;
        animation-delay: 6.8s;
      }
    }

    // character - supermarket - supermarket
    &.character-type-adult.to-supermarket:before,
    &.character-type-adult.to-supermarket:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(-1500);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1550);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-1690);
      }
    }

    &.character-type-adult.to-supermarket svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-53%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        transition-property: all;
        transition-duration: 4s;
        left: rem-calc(-1500);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1550);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-1690);
      }

      // travelling duration
      transition-property: all;
      transition-duration: 4s;
      transition-delay: 1.9s;

      .company {
        animation: slide-in-fwd-center 0.5s linear;
      }

      .company-outline {
        animation: fade-in 1.5s linear both;
        animation-delay: 0.3s;
        display: block;
      }

      .company-sign {
        animation: flicker 1s linear;
      }

      .supermarket {
        animation: slide-in-fwd-center 0.5s linear;
        animation-delay: 6s;
      }

      .supermarket-outline {
        animation: fade-in 1.5s linear both infinite;
        animation-delay: 6.3s;
        display: block;
      }

      .supermarket-sign {
        animation: flicker 1s linear both;
        animation-delay: 6.8s;
      }
    }

    // to dinner - delivery
    &.to-dinner-delivery:before,
    &.to-dinner-delivery:after {
      @media only screen and (max-width: $media-md) {
        transition-delay: 0s;
        left: rem-calc(-350);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-500);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-675);
      }
    }

    &.to-dinner-delivery svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-19%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-350);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-500);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-675);
      }

      // travelling duration
      transition-property: all;
      transition-duration: 4s;

      .restaurant {
        animation: slide-in-fwd-center 0.5s linear;
        animation-delay: 4.5s;
      }

      .restaurant-outline {
        animation: fade-in 1.5s linear both infinite;
        animation-delay: 5s;
        display: block;
      }

      .restaurant-sign {
        animation: flicker 1s linear both;
        animation-delay: 5.8s;
      }
    }

    // to - dinner - cooking

    &.to-dinner-cooking:before,
    &.to-dinner-cooking:after {
      @media only screen and (max-width: $media-md) {
        transition-delay: 0s;
        left: rem-calc(50);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-50);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-140);
      }
    }

    &.to-dinner-cooking svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(0);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(50);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-50);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-140);
      }

      // travelling duration
      transition-property: all;
      transition-duration: 4s;

      .home {
        animation: slide-in-fwd-center 0.5s linear;
        animation-delay: 4.5s;
      }

      .home-outline {
        animation: fade-in 1.5s linear both infinite;
        animation-delay: 5s;
        display: block;
      }

      .home-sign {
        animation: flicker 1s linear both;
        animation-delay: 5.8s;
      }
    }

    // character - child - to breaktime
    &.character-type-child.to-breaktime:before,
    &.character-type-child.to-breaktime:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(50);
        transition-property: all;
        transition-duration: 4s;
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-50);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-140);
      }
    }

    &.character-type-child.to-breaktime svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(0);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(50);
        transition-property: all;
        transition-duration: 4s;
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-50);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-140);
      }

      // travelling duration
      transition-property: all;
      transition-duration: 4s;
      transition-delay: 1.9s;

      .school {
        animation: slide-in-fwd-center 0.5s linear;
      }

      .school-outline {
        animation: fade-in 1.5s linear both;
        animation-delay: 0.3s;
        display: block;
      }

      .school-sign {
        animation: flicker 1s linear;
      }

      .home {
        animation: slide-in-fwd-center 0.5s linear;
        animation-delay: 6s;
      }

      .home-outline {
        animation: fade-in 1.5s linear both infinite;
        animation-delay: 6.3s;
        display: block;
      }

      .home-sign {
        animation: flicker 1s linear both;
        animation-delay: 6.8s;
      }
    }

    // character - child - to snacking
    &.character-type-child.to-snacking:before,
    &.character-type-child.to-snacking:after {
      @media only screen and (max-width: $media-md) {
        left: rem-calc(-1100);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1300);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-1380);
      }
    }

    &.character-type-child.to-snacking svg {
      @media only screen and (min-width: $media-md) {
        transform: translateX(-42%);
        left: auto;
        position: relative;
      }

      @media only screen and (max-width: $media-md) {
        left: rem-calc(-1100);
      }

      @media only screen and (max-width: $media-sm) {
        left: rem-calc(-1300);
      }

      @media only screen and (max-width: $media-xs) {
        left: rem-calc(-1380);
      }

      // travelling duration
      transition-property: all;
      transition-duration: 4s;
      transition-delay: 1.9s;

      .home {
        animation: slide-in-fwd-center 0.5s linear;
      }

      .home-outline {
        animation: fade-in 1.5s linear both;
        animation-delay: 0.3s;
        display: block;
      }

      .home-sign {
        animation: flicker 1s linear;
      }

      .hill {
        animation: slide-in-fwd-center 0.5s linear;
        animation-delay: 6s;
      }

      .hill-outline {
        animation: fade-in 1.5s linear both infinite;
        animation-delay: 6.3s;
        display: block;
      }

      .hill-sign {
        animation: flicker 1s linear both;
        animation-delay: 6.8s;
      }
    }
  }
}
